import React, { useContext } from "react";

import { ApartmentSliceProps } from "./Default";

import "./SliceFeatures.scss";
import { roomTypeToLabel } from "../../../utils/BasicFunctions";
import { ContextApp } from "../../../context";

const SliceFeatures: React.FC<ApartmentSliceProps> = ({ apartment }) => {
    const apartmentLoader = useContext<ContextApp>(ContextApp);

    const features = (apartment.props ?? []).map((prop, index) => {
        const propDefinition = apartmentLoader.ApartmentConfigLoader.propDefinitions.find((e) => e.id === prop.id);
        if (!propDefinition || !propDefinition.display) return null;

        return (
            <div className="apartment-slice-features__feature" key={index}>
                {propDefinition.name}
            </div>
        );
    });

    if (features.length === 0) return null;

    return <div className="apartment-slice-features">{features}</div>;
};

export default SliceFeatures;
