import React from "react";

import { ApartmentSliceProps } from "./Default";

import "./SliceLocationInfo.scss";

const SliceLocationInfo: React.FC<ApartmentSliceProps> = ({ apartment }) => {
    return (
        <div className="apartment-slice-location-info">
            <div className="apartment-slice-location-info__element">
                <div className="apartment-slice-location-info__element-label">Budynek</div>
                <div className="apartment-slice-location-info__element-value">{apartment.building}</div>
            </div>
            <div className="apartment-slice-location-info__element">
                <div className="apartment-slice-location-info__element-label">Piętro</div>
                <div className="apartment-slice-location-info__element-value">{apartment.floor}</div>
            </div>
            <div className="apartment-slice-location-info__element">
                <div className="apartment-slice-location-info__element-label">Pokoje</div>
                <div className="apartment-slice-location-info__element-value">{apartment.rooms}</div>
            </div>
        </div>
    );
};

export default SliceLocationInfo;
