import React from "react";
import ReactMarkdown from "react-markdown";

import { StaticSliceTypeText } from "../../../types/Static.types";

import "./StaticSliceText.scss";

interface Props {
    slice: StaticSliceTypeText;
}

const StaticSliceText: React.FC<Props> = ({ slice }) => {
    return (
        <div className="static-slice-text">
            <div className="static-slice-text__content">
                <ReactMarkdown source={slice.text} />
            </div>
        </div>
    );
};

export default StaticSliceText;
