import React from "react";

import FieldBase from "./FieldBase";
import FieldWrapper from "./FieldWrapper";

import "./Range.scss";

type Selector = "min" | "max";

interface SelectorObject<T> {
    min: T;
    max: T;
}

interface RangeProps {
    valueMin: number | undefined;
    valueMax: number | undefined;
    setValueMin: (valueMin: number | undefined) => void;
    setValueMax: (valueMax: number | undefined) => void;
    max: number | undefined;
    min: number | undefined;
}

interface State {
    tempValue: SelectorObject<number | undefined>;
}

export default class Range extends FieldBase<undefined, RangeProps, State> {
    state: State = {
        tempValue: {
            min: undefined,
            max: undefined,
        },
    };

    private active: SelectorObject<boolean> = {
        min: false,
        max: false,
    };

    private rangeRef = React.createRef<HTMLDivElement>();

    private mouseMoveListener = (e: MouseEvent) => {
        if (this.active.min || this.active.max) {
            this.onMouseMove(e.pageX);
        }
    };

    private mouseUpListener = () => {
        if (this.active.min || this.active.max) {
            this.onMouseUp();
        }
    };

    private touchMoveListener = (e: TouchEvent) => {
        if (this.active.min || this.active.max) {
            if (e.touches.length === 1) {
                this.onMouseMove(e.touches[0].pageX);
            }
        }
    };

    componentDidMount() {
        window.addEventListener("mousemove", this.mouseMoveListener);
        window.addEventListener("mouseup", this.mouseUpListener);
        window.addEventListener("touchend", this.mouseUpListener);
        window.addEventListener("touchmove", this.touchMoveListener);
    }

    componentWillUnmount() {
        window.removeEventListener("mousemove", this.mouseMoveListener);
        window.removeEventListener("mouseup", this.mouseUpListener);
        window.removeEventListener("touchend", this.mouseUpListener);
        window.removeEventListener("touchmove", this.touchMoveListener);
    }

    render() {
        return (
            <FieldWrapper {...this.fieldWrapperProps}>
                <div
                    className={`field-range ${
                        this.valueMin !== this.props.min || this.valueMax !== this.props.max
                            ? "is-changed"
                            : ""
                    }`}
                    ref={this.rangeRef}
                >
                    <div className="field-range__track"></div>
                    <div
                        className="field-range__selector left"
                        onMouseDown={(e) => this.onMouseDown("min")}
                        onTouchStart={() => this.onMouseDown("min")}
                        style={{ marginLeft: this.margin.min }}
                    >
                        <div className="field-range__selector-circle"></div>
                        <div className="field-range__selector-label">{this.valueMin ? this.valueMin : ""}</div>
                    </div>
                    <div className="field-range__track-fill"></div>
                    <div
                        className="field-range__selector right"
                        onMouseDown={(e) => this.onMouseDown("max")}
                        onTouchStart={() => this.onMouseDown("max")}
                        style={{ marginRight: this.margin.max }}
                    >
                        <div className="field-range__selector-circle"></div>
                        <div className="field-range__selector-label">{this.valueMax ? this.valueMax : ""}</div>
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    get valueMin() {
        return this.active.min ? this.state.tempValue.min : this.props.valueMin;
    }

    get valueMax() {
        return this.active.max ? this.state.tempValue.max : this.props.valueMax;
    }

    private onMouseDown(selector: Selector) {
        this.active[selector] = true;
        this.setState((p) => ({
            tempValue: { ...p.tempValue, [selector]: selector === "min" ? this.props.valueMin : this.props.valueMax },
        }));
    }

    private onMouseMove(x: number) {
        const element = this.rangeRef.current;

        if (
            !element ||
            this.props.min === undefined ||
            this.props.max === undefined ||
            this.valueMin === undefined ||
            this.valueMax === undefined
        ) {
            return;
        }

        const offsetLeft = element.getBoundingClientRect().left;

        const min = offsetLeft + 20;
        const max = offsetLeft + element.offsetWidth - 20;

        const deltaValue = this.props.max - this.props.min;
        const deltaX = max - min;

        if (x < min) {
            x = min;
        }

        if (x > max) {
            x = max;
        }

        const absX = x - min;

        const value = Math.round(this.props.min + (absX * deltaValue) / deltaX);

        if (this.active.min) {
            if (value <= this.valueMax) {
                this.setState((p) => ({ tempValue: { ...p.tempValue, min: value } }));
                // this.props.setValueMin(value);
            }
        } else if (this.active.max) {
            if (value >= this.valueMin) {
                this.setState((p) => ({ tempValue: { ...p.tempValue, max: value } }));
                // this.props.setValueMax(value);
            }
        }
    }

    private onMouseUp() {
        if (this.active.min) {
            this.props.setValueMin(this.state.tempValue.min);
        }
        if (this.active.max) {
            this.props.setValueMax(this.state.tempValue.max);
        }
        this.active = { min: false, max: false };
    }

    get margin(): { min: number; max: number } {
        const element = this.rangeRef.current;

        if (
            !element ||
            this.props.min === undefined ||
            this.props.max === undefined ||
            this.valueMin === undefined ||
            this.valueMax === undefined
        ) {
            return { min: 0, max: 0 };
        }

        const min = element.offsetLeft + 20;
        const max = element.offsetLeft + element.offsetWidth - 20;

        const deltaValue = this.props.max - this.props.min;
        const deltaX = max - min;

        const minAbsValue = this.valueMin - this.props.min;
        const maxAbsValue = this.props.max - this.valueMax;

        let minX = (minAbsValue * deltaX) / deltaValue;
        let maxX = (maxAbsValue * deltaX) / deltaValue;

        if (minX > deltaX - 1) {
            minX = deltaX - 1;
        }

        if (maxX > deltaX - 1) {
            maxX = deltaX - 1;
        }

        return { min: minX, max: maxX };
    }
}
