import React from "react";
import Check from "../../form/Check";
import RadioBar from "../../form/RadioBar";
import Range from "../../form/Range";
import { RangeValue } from "../../types/Generic.types";
import { BaseComponent } from "../../utils/BaseComponent";
import { labelByCount } from "../../utils/BasicFunctions";
import Collapse from "../collapse/Collapse";
import "./ApartmentFilters.scss";

type ApartmentStatus = 0 | 1 | 2 | 3;

export interface FilterFields {
    props: { [id: number]: boolean };
    status: 0 | 1 | 2 | 3;
    area: RangeValue;
    floor: RangeValue;
    rooms: RangeValue;
    visibleOnly: boolean;
}

interface Props {
    filters: FilterFields;
    filtersSet: (setFunction: (filters: FilterFields) => Partial<FilterFields>) => void;
}

const mainFilters: { label: string; value: "floor" | "rooms" | "area" }[] = [
    { label: "Piętro", value: "floor" },
    { label: "Pokoje", value: "rooms" },
    { label: "Powierzchnia", value: "area" },
];

interface State {
    isCollapsed: boolean;
}

export default class ApartmentFilters extends BaseComponent<Props, State> {
    state: State = {
        isCollapsed: window.outerWidth > 400 ? false : true,
    };

    render() {
        const filters = this.props.filters;
        const filtersSet = this.props.filtersSet;

        return (
            <div className="apartment-filters">
                <div className="apartment-filters__top">
                    <div className="apartment-filters__title">Filtry</div>
                    <div className="apartment-filters__short">
                        {this.props.filters.area.min}
                        {this.props.filters.area.min !== this.props.filters.area.max ? (
                            <>&nbsp;-&nbsp;{this.props.filters.area.max}</>
                        ) : null}
                        &nbsp;M<sup>2</sup>
                        &nbsp;&nbsp;/&nbsp;&nbsp;
                        {this.props.filters.floor.min}
                        {this.props.filters.floor.max !== this.props.filters.floor.min ? (
                            <>&nbsp;-&nbsp;{this.props.filters.floor.max}</>
                        ) : null}
                        &nbsp;PIĘTRO &nbsp;&nbsp;/&nbsp;&nbsp;
                        {this.props.filters.rooms.min}
                        {this.props.filters.rooms.min !== this.props.filters.rooms.max ? (
                            <>&nbsp;-&nbsp;{this.props.filters.rooms.max}&nbsp;POKOI</>
                        ) : (
                            <>
                                &nbsp;
                                {labelByCount(this.props.filters.rooms.min, "POKOI", [
                                    ["POKÓJ", 1],
                                    ["POKOJE", 2, 4],
                                ])}
                            </>
                        )}
                    </div>
                </div>
                <div
                    className="apartment-filters__button"
                    onClick={() => this.setState((p) => ({ isCollapsed: !p.isCollapsed }))}
                >
                    <div className={`apartment-filters__button-icon ${this.state.isCollapsed ? "is-collapsed" : ""}`}>
                        {this.state.isCollapsed ? "keyboard_arrow_down" : "keyboard_arrow_up"}
                    </div>
                    <div className={`apartment-filters__button-label ${this.state.isCollapsed ? "is-collapsed" : ""}`}>
                        {this.state.isCollapsed ? "rozwiń by filtrować mieszkania" : "zwiń"}
                    </div>
                </div>
                <Collapse isCollapsed={this.state.isCollapsed}>
                    <div className="apartment-filters__collapsable">
                        <div className="apartment-filters__header">
                            <RadioBar
                                label="Status mieszkania"
                                value={this.props.filters.status}
                                options={[
                                    { value: 0, label: "Wszystkie" },
                                    { value: 1, label: "Dostępne" },
                                    { value: 2, label: "Rezerwacja" },
                                    { value: 3, label: "Sprzedane" },
                                ]}
                                onChange={(status) => filtersSet(() => ({ status: status as 0 | 1 | 2 | 3 }))}
                            />
                        </div>
                        <div className="apartment-filters__columns">
                            <div className="apartment-filters__column">
                                {mainFilters.map((f) => (
                                    <Range
                                        key={f.value}
                                        value={undefined}
                                        label={f.label}
                                        valueMin={filters[f.value].min}
                                        valueMax={filters[f.value].max}
                                        min={APP_CONFIG.filtersRangeValues[f.value].min}
                                        max={APP_CONFIG.filtersRangeValues[f.value].max}
                                        setValueMin={(v) =>
                                            filtersSet((p) => ({
                                                [f.value]: {
                                                    ...p[f.value],
                                                    min: v || APP_CONFIG.filtersRangeValues[f.value].min,
                                                },
                                            }))
                                        }
                                        setValueMax={(v) =>
                                            filtersSet((p) => ({
                                                [f.value]: {
                                                    ...p[f.value],
                                                    max: v || APP_CONFIG.filtersRangeValues[f.value].max,
                                                },
                                            }))
                                        }
                                        onChange={() => {}}
                                    />
                                ))}
                            </div>
                            <div className="apartment-filters__column">
                                {this.context.ApartmentConfigLoader.propDefinitions.map((pd) => {
                                    if (!pd.filter) return null;

                                    switch (pd.type) {
                                        case "boolean":
                                            return (
                                                <Check
                                                    key={pd.id}
                                                    value={filters.props[pd.id]}
                                                    onChange={(value) =>
                                                        filtersSet((p) => ({ props: { ...p.props, [pd.id]: value } }))
                                                    }
                                                    label={pd.name}
                                                />
                                            );

                                        default:
                                            return null;
                                    }
                                })}
                                <div className="apartment-filters__check-fake"></div>
                                <div className="apartment-filters__check-fake"></div>
                                <div className="apartment-filters__check-fake"></div>
                            </div>
                            <div className="apartment-filters__footer">
                                <Check
                                    value={filters.visibleOnly}
                                    onChange={(v) => filtersSet(() => ({ visibleOnly: v }))}
                                    label="Tylko widoczne apartamenty"
                                />
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }
}
