import React from "react";

import { BaseComponent } from "../utils/BaseComponent";
import logo from "../assets/logo.png";

import "./Static.scss";
import { StaticPage } from "../types/Static.types";
import StaticSlices from "../components/staticSlices/StaticSlices";

interface Props {
    static: StaticPage;
}

export default class Static extends BaseComponent<Props> {
    render() {
        return (
            <div className={`static  ${this.static.headerType || ""}`}>
                <div className={`static__header`}>
                    <div className="static__header-left">
                        <img src={logo} alt="" className="static__header-logo" />
                        <div className="static__header-title">{this.static.title}</div>
                    </div>
                    <div className="button-menu" onClick={() => this.context.Common.navOpen()}>
                        menu
                    </div>
                </div>
                <div className="static__content">
                    <StaticSlices slices={this.static.slices} />
                </div>
            </div>
        );
    }

    get static() {
        return this.props.static;
    }
}
