import React from "react";

import { BaseComponent } from "../../utils/BaseComponent";

import "./ApartmentList.scss";
import { Apartment, ApartmentsButton } from "../../types/Config.types";
import { availabilityToLabel, labelByCount, availabilityToClassName } from "../../utils/BasicFunctions";
import { Link } from "react-router-dom";
import RadioBar from "../../form/RadioBar";

interface Props {
    filters: React.ReactNode;
    apartments: Apartment[];
    currentButton: ApartmentsButton;
    apartmentHoverId: string | undefined;
    onApartmentOver: (apartmentId: string) => void;
    onApartmentOut: (apartmentId: string) => void;
    navOpen: () => void;
    openApartment: (apartmentId: string) => void;
}

interface State {
    viewMode: 1 | 2;
}

export default class ApartmentList extends BaseComponent<Props, State> {
    state: State = {
        viewMode: 2,
    };

    render() {
        return (
            <div className="apartment-list">
                <div className="apartment-list__header">
                    <div className="apartment-list__header-bar">
                        <div className="apartment-list__header-title">
                            <b>Mieszkania</b>
                        </div>
                        <div className="apartment-list__header-icon" onClick={() => this.context.Common.navOpen()}>
                            menu
                        </div>
                    </div>
                    <div className="apartment-list__header-building">{this.props.currentButton.title}</div>
                    <div className="apartment-list__header-view-mode">
                        <div className="apartment-list__header-free-apartments">
                            <b>{this.props.apartments.length}</b>{" "}
                            {labelByCount(this.props.apartments.length, "mieszkań", [
                                ["mieszkanie", 1],
                                ["mieszkań", 12, 14],
                                ["mieszkań", 21, 24],
                                ["mieszkania", 2, 4, true],
                            ])}
                        </div>
                        {/* <div
                            className="apartment-list__header-view-mode-button"
                            onClick={() => this.setState((p) => ({ viewTable: !p.viewTable }))}
                        >
                            <div className="apartment-list__header-view-mode-icon">
                                {!this.state.viewTable ? "view_column" : "view_module"}
                            </div>
                            <div className="apartment-list__header-view-mode-label">
                                {!this.state.viewTable ? "Widok listy" : "Widok kart"}
                            </div>
                        </div> */}
                    </div>
                    {this.props.filters}
                    <div className="apartment-list__view-mode-wrapper">
                        <div className="apartment-list__view-mode-title">Mieszkania</div>
                        <RadioBar
                            className="wide"
                            label=""
                            value={this.state.viewMode}
                            options={[
                                { value: 2, label: "Karty", icon: "cards" },
                                { value: 1, label: "Lista", icon: "list" },
                            ]}
                            onChange={(value) => this.setState({ viewMode: value as 1 | 2 })}
                        />
                        {/* <div className="apartment-list__view-mode-button">
                            <div
                                className={`apartment-list__view-mode-option ${
                                    this.state.viewMode === 2 ? "is-active" : ""
                                }`}
                                onClick={() => this.setState({ viewMode: 2 })}
                            >
                                <div className="apartment-list__view-mode-option-icon">view_module</div>
                                <div className="apartment-list__view-mode-option-label">Karty</div>
                            </div>
                            <div
                                className={`apartment-list__view-mode-option ${
                                    this.state.viewMode === 1 ? "is-active" : ""
                                }`}
                                onClick={() => this.setState({ viewMode: 1 })}
                            >
                                <div className="apartment-list__view-mode-option-icon">view_column</div>
                                <div className="apartment-list__view-mode-option-label">Lista</div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="apartment-list__content">
                    {this.props.apartments.length === 0 ? (
                        <div className="apartment-list__empty-info">
                            <b>Nie znaleziono żadnych mieszkań</b>
                            <br />
                            Usuń niektóre filtry by poszerzyć zakres wyszukiwania
                        </div>
                    ) : null}
                    {this.state.viewMode === 1 ? (
                        <div className="apartment-list__table-wrapper">
                            <table className="apartment-list__table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="title">
                                                <div className="title-rotate">Mieszkanie</div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="title">
                                                <div className="title-rotate">Budynek</div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="title">
                                                <div className="title-rotate">Powierzchnia</div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="title">
                                                <div className="title-rotate">Piętro</div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="title">
                                                <div className="title-rotate">Pokoje</div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="title">
                                                <div className="title-rotate">Porównaj</div>
                                            </div>
                                        </th>
                                        {/* <th>Status</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.apartments.map((a) => (
                                        <tr
                                            key={a.id}
                                            onClick={() => this.props.openApartment(a.id)}
                                            className={`${a.id === this.props.apartmentHoverId ? "is-hover" : ""}`}
                                            onMouseOver={() => this.props.onApartmentOver(a.id)}
                                            onMouseOut={() => this.props.onApartmentOut(a.id)}
                                        >
                                            <td className={`cell-name ${availabilityToClassName(a.availability)}`}>
                                                {a.name}
                                            </td>
                                            <td>{a.building}</td>
                                            <td>
                                                {a.area} m<sup>2</sup>
                                            </td>
                                            <td>{a.floor}</td>
                                            <td>{a.rooms}</td>
                                            <td>
                                                <button
                                                    className={`button icon small ${
                                                        this.context.Compare.isSelected(a.id) ? "is-on" : ""
                                                    }`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.context.Compare.change(a.id);
                                                    }}
                                                    title="Dodaj do porównania"
                                                >
                                                    favorite
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="apartment-list__list">
                            {this.props.apartments.map((a, index) => (
                                <div
                                    className={`apartment-list__card ${
                                        a.id === this.props.apartmentHoverId ? "is-hover" : ""
                                    }`}
                                    key={a.id}
                                    onMouseOver={() => this.props.onApartmentOver(a.id)}
                                    onMouseOut={() => this.props.onApartmentOut(a.id)}
                                    onClick={() => this.props.openApartment(a.id)}
                                >
                                    <div className="apartment-list__card-content">
                                        <div className="apartment-list__card-left">
                                            <div className="apartment-list__card-title">{a.name}</div>
                                            <div
                                                className={`apartment-list__card-availability ${availabilityToClassName(
                                                    a.availability
                                                )}`}
                                            >
                                                {availabilityToLabel(a.availability)}
                                            </div>
                                            <div className="apartment-list__card-prop">
                                                <div className="apartment-list__card-prop-label">Powierzchnia</div>
                                                <div className="apartment-list__card-prop-value">
                                                    {a.area} m<sup>2</sup>
                                                </div>
                                            </div>
                                            <div className="apartment-list__card-prop">
                                                <div className="apartment-list__card-prop-label">Liczba Pokoi</div>
                                                <div className="apartment-list__card-prop-value">{a.rooms}</div>
                                            </div>
                                            <div className="apartment-list__card-prop">
                                                <div className="apartment-list__card-prop-label">Piętro</div>
                                                <div className="apartment-list__card-prop-value">{a.floor}</div>
                                            </div>
                                        </div>
                                        <div className="apartment-list__card-right">
                                            <div className="apartment-list__card-layout">
                                                <img src={a.layout} />
                                            </div>
                                            <div
                                                className={`apartment-list__card-compare-button ${
                                                    this.context.Compare.isSelected(a.id) ? "is-selected" : ""
                                                }`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.context.Compare.change(a.id);
                                                }}
                                                title="Dodaj do porównania"
                                            >
                                                favorite
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className="apartment-list__card-fake"></div>
                            <div className="apartment-list__card-fake"></div>
                            <div className="apartment-list__card-fake"></div>
                            <div className="apartment-list__card-fake"></div>
                        </div>
                    )}
                    <div
                        className={`apartment-list__compare-button-wrapper ${
                            this.context.Compare.storage.apartmentIds.length > 0 && this.props.apartments.length > 0
                                ? "is-active"
                                : ""
                        }`}
                    >
                        <Link to="/Compare" className="button is-on">
                            Porównaj mieszkania
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
