import React from "react";

import { BaseComponent } from "../utils/BaseComponent";
import Nav from "../components/Nav";
import logo from "../assets/logo.png";

import "./Start.scss";

export default class Start extends BaseComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="start">
                <div className="start__video">
                    <video className="start__video-video" autoPlay={true} muted={true} loop={true}>
                        <source src="start-video.mp4" type="video/mp4" />
                    </video>
                </div>

                <img src={logo} className="start__logo" />

                <div className="start__sidebar">
                    <Nav showNavClose={false} hideOverlay={true} />
                </div>
            </div>
        );
    }
}
