import React from "react";

import { BaseComponent } from "../../utils/BaseComponent";
import { Overlay, OverlayProps } from "../Overlay";
import { DollHousePlugin } from "./DollHousePlugin";

import "./DollHouse.scss";
import { AngleFromXY, AngleDelta } from "../../utils/BasicFunctions";

interface RotateData {
    active: boolean;
    startAngle: number | undefined;
    centerX: number | undefined;
    centerY: number | undefined;
    startFrame: number | undefined;
}

export interface DollHouseProps {
    dollHousePlugin: DollHousePlugin;
    className?: string;
}

export default class DollHouse extends BaseComponent<DollHouseProps> {
    private rotateData: RotateData = {
        active: false,
        startAngle: undefined,
        centerX: undefined,
        centerY: undefined,
        startFrame: undefined,
    };

    private dollHouseRef = React.createRef<HTMLDivElement>();

    render() {
        return (
            <div className={`dollHouse ${this.props.className || ""}`} ref={this.dollHouseRef}>
                <div className="dollHouse__loader">
                    <Overlay {...this.overlayProps} />
                </div>
                <div className="dollHouse__buttons-bottom">
                    <button className="button icon" onClick={() => this.plugin.scale(1)}>
                        add
                    </button>
                    <button className="button icon" onClick={() => this.plugin.scale(-1)}>
                        remove
                    </button>
                    <button className="button icon" onClick={() => this.plugin.move(-1)}>
                        keyboard_arrow_left
                    </button>
                    <button className="button icon" onClick={() => this.plugin.move(1)}>
                        keyboard_arrow_right
                    </button>
                </div>
                <div
                    className={`dollHouse__images-container index-${this.plugin.storage.frameIndex}`}
                    style={{ transform: `scale(${this.plugin.storage.scale})` }}

                    // onContextMenu={(e) => e.preventDefault()}
                >
                    <div className="dollHouse__images" ref={this.plugin.imagesRef}></div>
                </div>

                <div
                    className="dollHouse__touch-area"
                    onMouseDown={(e) => this.rotateStart(e.clientX, e.clientY)}
                    onMouseMove={(e) => this.rotateMove(e.clientX, e.clientY)}
                    onMouseLeave={() => this.rotateStop()}
                    onMouseUp={() => this.rotateStop()}
                    onTouchStartCapture={(event) => this.onTouchStart(event)}
                    onTouchMove={(event) => this.onTouchMove(event)}
                    onTouchEnd={() => this.rotateStop()}
                ></div>
            </div>
        );
    }

    get plugin() {
        return this.props.dollHousePlugin;
    }

    get overlayProps(): OverlayProps {
        const viewLoaderState = this.context.DollHouseLoader.storage.DollHouses[this.plugin.storage.dollHouseId];
        if (!viewLoaderState) {
            return {
                show: true,
                title: "Widok nie istnieje",
            };
        }
        if (viewLoaderState.LoadState.state === "idle") {
            return {
                show: true,
                title: "Oczekuje na pobieranie Doll House...",
            };
        }
        if (viewLoaderState.LoadState.state === "pending") {
            return {
                show: true,
                title: "Pobieranie Doll House...",
                children: (
                    <div className="viewer__overlay-progress">
                        {Math.round((viewLoaderState.LoadState.percent * 8) / 10)}%
                    </div>
                ),
            };
        }
        if (viewLoaderState.LoadState.state === "error") {
            return {
                show: true,
                title: "Błąd podczas pobierania Doll House",
                children: (
                    <div>
                        <button
                            onClick={() =>
                                this.context.DollHouseLoader.unzipQueueAddForce(this.plugin.storage.dollHouseId)
                            }
                        >
                            Pobierz ponownie
                        </button>
                    </div>
                ),
            };
        }
        if (viewLoaderState.UnzipState.state === "idle") {
            return {
                show: true,
                title: "Rozpakowywanie Doll House",
                children: <div className="viewer__overlay-progress">80%</div>,
            };
        }
        if (viewLoaderState.UnzipState.state === "pending") {
            return {
                show: true,
                title: "Rozpakowywanie Doll House",
                children: (
                    <div className="viewer__overlay-progress">
                        {80 + Math.round((viewLoaderState.UnzipState.percent * 2) / 10)}%
                    </div>
                ),
            };
        }
        if (viewLoaderState.UnzipState.state === "error") {
            return {
                show: true,
                title: "Błąd podczas rozpakowywania Doll House",
                children: (
                    <div>
                        <button
                            onClick={() =>
                                this.context.DollHouseLoader.unzipQueueAddForce(this.plugin.storage.dollHouseId)
                            }
                        >
                            Pobierz ponownie
                        </button>
                    </div>
                ),
            };
        }
        return {
            show: false,
        };
    }

    private rotateStart(x: number, y: number) {
        const container = this.dollHouseRef.current;
        if (!!container) {
            this.rotateData.centerX = container.offsetWidth / 2;
            this.rotateData.centerY = container.offsetHeight / 2 + container.getBoundingClientRect().top;

            this.rotateData.startAngle = AngleFromXY(this.rotateData.centerX, this.rotateData.centerY, x, y);
        } else {
            this.rotateData.startAngle = x;
        }

        console.log(this.rotateData, x, y);

        this.rotateData.active = true;
        this.rotateData.startFrame = this.plugin.storage.frameIndex;
    }

    private rotateMove(x: number, y: number) {
        if (!this.rotateData.active) return;
        const max = this.plugin.currentDollHouseConfig?.framesCount || 0;

        if (!!this.rotateData.centerX && !!this.rotateData.centerY) {
            const currentAngle = AngleFromXY(this.rotateData.centerX, this.rotateData.centerY, x, y);
            const deltaAngle = AngleDelta(this.rotateData.startAngle || 0, currentAngle);

            const deltaIndex = max - Math.round((deltaAngle * max) / 360);

            const newIndex = ((this.rotateData.startFrame || 0) + deltaIndex) % max;

            this.plugin.setFrameIndex(newIndex);
        } else {
        }
    }

    private rotateStop() {
        this.rotateData.active = false;
        this.rotateData.startAngle = undefined;
        this.rotateData.startFrame = undefined;
        this.rotateData.centerX = undefined;
        this.rotateData.centerY = undefined;
    }

    private onTouchStart(event: React.TouchEvent<HTMLDivElement>) {
        this.rotateStart(event.touches[0].clientX, event.touches[0].clientY);
    }

    private onTouchMove(event: React.TouchEvent<HTMLDivElement>) {
        this.rotateMove(event.touches[0].clientX, event.touches[0].clientY);
    }
}
