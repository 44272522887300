import React from "react";
import { BaseComponent } from "../utils/BaseComponent";
import { Link, NavLink } from "react-router-dom";

import logo from "../assets/logo.png";

import "./Nav.scss";
import { ROUTES } from "../areas/Router";

interface Props {
    showNavClose: boolean;
    navClose?: () => void;
    hideOverlay?: boolean;
    isClosed?: boolean;
}

export default class Nav extends BaseComponent<Props> {
    render() {
        return (
            <div
                className={`nav ${this.props.hideOverlay ? "" : "is-overlay"} ${
                    this.props.isClosed ? "is-closed" : ""
                }`}
            >
                <div className="nav__background"></div>
                <div className="nav__close" onClick={() => this.close()}>
                    close
                </div>

                <div className="nav__logo">
                    <img src={logo} className="nav__logo-img" alt="" />
                </div>
                <div className="nav__photo">
                    <img src="/Gallery/start-video_Moment2.jpg" alt="" className="nav__photo-img" />
                    <div className="nav__photo-overlay"></div>
                </div>
                {/* <div className="nav__title">
                    <div className="nav__title-1">
                        Osiedle
                        <br />
                        Złota Ostoja
                    </div>
                    <div className="nav__title-3">Etap 3</div>
                    <div className="nav__title-2">
                        Przestrzeń&nbsp;&nbsp;&nbsp;&nbsp;
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;możliwości
                    </div>
                </div> */}
                <div className="nav__buttons">
                    {/* <NavLink to="/Start" className="nav__link" onClick={() => this.close(true)}>
                        Start
                    </NavLink> */}
                    <NavLink to={ROUTES.Location} className="nav__link" onClick={() => this.close(true)}>
                        Lokalizacja
                    </NavLink>
                    <NavLink to="/Apartments" className="nav__link" onClick={() => this.close(true)}>
                        Mieszkania
                    </NavLink>
                    <NavLink to="/Compare" className="nav__link" onClick={() => this.close(true)}>
                        Porównaj Mieszkania
                    </NavLink>
                    <NavLink to={ROUTES.About} className="nav__link" onClick={() => this.close(true)}>
                        O Inwestycji
                    </NavLink>
                    <NavLink to={ROUTES.Gallery} className="nav__link" onClick={() => this.close(true)}>
                        Galeria
                    </NavLink>
                </div>
                {/* <div className="nav__center">
                    <div className="nav__top">
                        {this.props.showNavClose ? (
                            <div className="nav__header">
                                <div className="nav__header-close" onClick={() => this.close()}>
                                    close
                                </div>
                            </div>
                        ) : null}
                        <div className="nav__title">
                            Osiedle
                            <br />
                            Złota Ostoja
                            <br />
                            <div className="nav__title-small">Etap 3</div>
                        </div>
                        <div className="nav__caption">
                            Przestrzeń&nbsp;&nbsp;&nbsp;&nbsp;
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;możliwości
                        </div>
                    </div>
                    <div className="nav__middle">
                        <NavLink to="/Start" className="nav__link" onClick={() => this.close(true)}>
                            Start
                        </NavLink>
                        <NavLink to={ROUTES.Location} className="nav__link" onClick={() => this.close(true)}>
                            Lokalizacja
                        </NavLink>
                        <NavLink to="/Apartments" className="nav__link" onClick={() => this.close(true)}>
                            Mieszkania
                        </NavLink>
                        <NavLink to="/Compare" className="nav__link" onClick={() => this.close(true)}>
                            Porównaj Mieszkania
                        </NavLink>
                        <NavLink to={ROUTES.About} className="nav__link" onClick={() => this.close(true)}>
                            O Inwestycji
                        </NavLink>
                        <NavLink to={ROUTES.Gallery} className="nav__link" onClick={() => this.close(true)}>
                            Galeria
                        </NavLink>
                    </div>
                    <div className="nav__bottom">Demo technologiczne 3D Estate Sp. z o.o.</div>
                </div> */}
            </div>
        );
    }

    private close(noScroll?: boolean) {
        this.context.Common.navClose(noScroll);
        this.props.navClose && this.props.navClose();
    }
}
