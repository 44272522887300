import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { ApartmentSliceProps } from "./Default";
import { availabilityToLabel, availabilityToClassName } from "../../../utils/BasicFunctions";

import "./SliceAvailability.scss";
import { ContextApp } from "../../../context";

const SliceAvailability: React.FC<ApartmentSliceProps> = ({ apartment }) => {
    const context = useContext(ContextApp);

    const isSelectedCompare = context.Compare.isSelected(apartment.id);

    return (
        <div className={`apartment-slice-availability ${availabilityToClassName(apartment.availability)}`}>
            <div className="apartment-slice-availability__label">{availabilityToLabel(apartment.availability)}</div>
            <div className="apartment-slice-availability__right">
                <Link
                    to="/Compare"
                    className={`apartment-slice-availability__button ${isSelectedCompare ? "is-visible" : ""}`}
                >
                    Przejdź do
                    <br />
                    porównania
                </Link>
                <button
                    className={`button icon ${isSelectedCompare ? "is-on" : ""}`}
                    onClick={() => context.Compare.change(apartment.id)}
                >
                    favorite
                </button>
            </div>
        </div>
    );
};

export default SliceAvailability;
