import React from "react";

import { BaseComponent } from "../../utils/BaseComponent";

import "./ApartmentDetails.scss";
import { Apartment, ApartmentsButton } from "../../types/Config.types";
import { availabilityToLabel, labelByCount } from "../../utils/BasicFunctions";
import floorPlan from "../../assets/floor-layout.png";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import ApartmentDetailsSlices from "./slices/Slices";

interface Props {
    apartment: Apartment;
    goToSearch: () => void;
}

interface State {
    isLoading: boolean;
}

export default class ApartmentDetails extends BaseComponent<Props, State> {
    state: State = {
        isLoading: true,
    };

    componentDidMount() {
        this._isMounted = true;

        window.setTimeout(() => {
            if (!!this.isMounted) {
                this.setState({ isLoading: false });
            }
        }, 100);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.apartment !== prevProps.apartment) {
            console.log("new apartment");
            this.setState({ isLoading: true }, () => {
                window.setTimeout(() => {
                    if (!!this.isMounted) {
                        this.setState({ isLoading: false });
                    }
                }, 50);
            });
        }
    }

    render() {
        const isSelectedCompare = this.context.Compare.isSelected(this.apartment.id);
        return (
            <div className="apartment-details">
                <div className="apartment-details__header">
                    Mieszkanie&nbsp;<b>{this.apartment.name}</b>
                </div>

                <div onClick={() => this.props.goToSearch()} className="apartment-details__go-back">
                    <i>keyboard_arrow_left</i>Wróć do wyszukiwarki mieszkań
                </div>

                <div className="apartment-details__slices">
                    <div className={`apartment-details__loader ${this.state.isLoading ? "is-active" : ""}`}></div>
                    <ApartmentDetailsSlices
                        apartment={this.props.apartment}
                        slices={APP_CONFIG.apartmentDetailsConfig.slices}
                    />
                </div>

                <div className="apartment-details__buttons">
                    {this.apartment.apartmentCardUrl ? (
                        <a
                            href={this.apartment.apartmentCardUrl}
                            target="_blank"
                            className="button apartment-details__card-button"
                        >
                            Karta mieszkania
                        </a>
                    ) : null}
                    <div className="apartment-details__compare">
                        <button
                            className={`button ${isSelectedCompare ? "" : "is-on"}`}
                            onClick={() => this.context.Compare.change(this.apartment.id)}
                        >
                            <i className="icon">{isSelectedCompare ? "close" : "favorite"}</i>{" "}
                            {isSelectedCompare ? "usuń" : "dodaj do porównania"}
                        </button>
                        {isSelectedCompare ? (
                            <Link to="/Compare" className="button is-on">
                                Porównaj
                            </Link>
                        ) : null}
                    </div>
                </div>

                {/* <div className="apartment-details__info-availability">
                    {this.apartment.availability === 1
                        ? "Dostępne"
                        : this.apartment.availability === 2
                        ? "Rezerwacja"
                        : "Sprzedane"}
                </div>
                <div className="apartment-details__info-row">
                    <div className="apartment-details__info big">
                        <div className="apartment-details__info-label">powierzchnia</div>
                        <div className="apartment-details__info-value">
                            {this.apartment.area} m<sup>2</sup>
                        </div>
                    </div>

                    <div className="apartment-details__info right">
                        <div className="apartment-details__info-label">kod mieszkania</div>
                        <div className="apartment-details__info-value">{this.apartment.id}</div>
                    </div>
                </div>

                <div className="apartment-details__info-box">
                    <div className="apartment-details__info-box-tiles">
                        <div className="apartment-details__info-box-tile">
                            Budynek <b>{this.apartment.building}</b>
                        </div>
                        <div className="apartment-details__info-box-tile">
                            Piętro <b>{this.apartment.floor}</b>
                        </div>
                        <div className="apartment-details__info-box-tile">
                            Pokoje <b>{this.apartment.rooms}</b>
                        </div>
                    </div>

                    <div className="apartment-details__info-box-lines">
                        <div className="apartment-details__info-box-line">
                            Taras o powierzchni 21 m<sup>2</sup>
                        </div>
                        <div className="apartment-details__info-box-line">
                            Przestonna kuchnia doświetlona z dwóch stron
                        </div>
                        <div className="apartment-details__info-box-line">Dźwiękoszczelne okna</div>
                        <div className="apartment-details__info-box-line">
                            Świetne nasłonecznienie - południowo-zachodnia orientacja
                        </div>
                    </div>
                </div>

                <div className="apartment-details__scrollable">
                    <div className="apartment-details__info-row">
                        <div className="apartment-details__info-areas">
                            <div className="apartment-details__info-areas-title">Metraż pomieszczeń:</div>

                            <div className="apartment-details__info-area">
                                <div className="apartment-details__info-area-label">Przedpokój</div>
                                <div className="apartment-details__info-area-value">
                                    9,09m<sup>2</sup>
                                </div>
                            </div>

                            <div className="apartment-details__info-area">
                                <div className="apartment-details__info-area-label">Łazienka</div>
                                <div className="apartment-details__info-area-value">
                                    5,34<sup>2</sup>
                                </div>
                            </div>

                            <div className="apartment-details__info-area">
                                <div className="apartment-details__info-area-label">Pokój Dzienny</div>
                                <div className="apartment-details__info-area-value">
                                    17,09m<sup>2</sup>
                                </div>
                            </div>

                            <div className="apartment-details__info-area">
                                <div className="apartment-details__info-area-label">Sypialnia</div>
                                <div className="apartment-details__info-area-value">
                                    12,09m<sup>2</sup>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="apartment-details__compare">
                        {this.context.Compare.isSelected(this.props.apartment.id) ? (
                            <>
                                <div
                                    className="apartment-details__compare-button  button is-active"
                                    onClick={() => this.context.Compare.change(this.props.apartment.id)}
                                >
                                    <i className="icon">bar_chart</i> usuń z porównania
                                </div>
                                <Link to="/Compare" className="apartment-details__compare-button button">
                                    pokaż porównanie
                                </Link>
                            </>
                        ) : (
                            <div
                                className="apartment-details__compare-button button"
                                onClick={() => this.context.Compare.change(this.props.apartment.id)}
                            >
                                <i className="icon">bar_chart</i> dodaj do porównania
                            </div>
                        )}
                    </div> */}

                {/* <div className="apartment-details__floor-layout">
                        <img src={floorPlan} className="apartment-details__floor-layout-img" />
                    </div> */}
                {/* </div> */}
            </div>
        );
    }

    get apartment() {
        return this.props.apartment;
    }
}
