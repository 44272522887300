import React from "react";
import ReactMarkdown from "react-markdown";

import { StaticSliceTypeImageText } from "../../../types/Static.types";

import "./StaticSliceImageText.scss";

interface Props {
    slice: StaticSliceTypeImageText;
}

const StaticSliceImageText: React.FC<Props> = ({ slice }) => {
    return (
        <div className={`static-slice-image-text ${slice.direction}`}>
            <div className={`static-slice-image-text__image-wrapper ${slice.imgClass || ""}`}>
                <div className={`static-slice-image-text__image-content `}>
                    <img src={slice.img} alt="" className="static-slice-image-text__image" />
                </div>
            </div>
            <div className="static-slice-image-text__text">
                <div className="static-slice-image-text__content">
                    <ReactMarkdown source={slice.text} />
                </div>
            </div>
        </div>
    );
};

export default StaticSliceImageText;
