import axios, { Canceler } from "axios";
import JSZip from "jszip";

import { BaseContext, ContextStorage } from "./Base";
import {
    DollHouseStorageObject,
    DollHouseDataStorage,
    WindowDollHouseData,
    DollHouseQueueElement
} from "./DollHouseLoader.types";
import { ProgressState, Callback } from "../types/Generic.types";

export interface DollHouseLoaderStorage {
    DollHouses: DollHouseStorageObject;
}

export type DollHouseLoaderStorageKeyType = "DollHouseLoader";

export const DollHouseLoaderStorageKey: DollHouseLoaderStorageKeyType = "DollHouseLoader";

export const ERROR_DOLL_HOUSE_LOADER_DOLL_HOUSE_DOES_NOT_EXIST = new Error("DollHouseDoesNotExist");
export const ERROR_DOLL_HOUSE_LOADER_DOLL_HOUSE_CONFIG_DOES_NOT_EXIST = new Error("DollHouseDoesConfigNotExist");
export const ERROR_DOLL_HOUSE_LOADER_DOLL_HOUSE_DATA_DOES_NOT_EXIST = new Error("DollHouseDoesDataNotExist");
export const ERROR_DOLL_HOUSE_LOADER_DOLL_HOUSE_ZIP_NOT_LOADED = new Error("DollHouseZipNotLoaded");

export const DollHouseLoaderStorageDefault: ContextStorage<DollHouseLoaderStorageKeyType, DollHouseLoaderStorage> = {
    DollHouseLoader: {
        DollHouses: {}
    }
};

export class DollHouseLoader extends BaseContext<DollHouseLoaderStorage, DollHouseLoaderStorageKeyType> {
    private loadQueue: DollHouseQueueElement[] = [];
    private loadCanceler: Canceler | undefined;
    private loadInProgress: boolean = false;

    private unzipQueue: DollHouseQueueElement[] = [];
    private unzipInProgress: boolean = false;

    public async init() {
        window.DOLL_HOUSES = APP_CONFIG.dollHouses.reduce((dollHouses, dollHouse) => {
            dollHouses[dollHouse.id] = {
                Zip: undefined,
                Images: []
            };
            return dollHouses;
        }, {} as WindowDollHouseData);
        await this.parentSetStateAsync(() => ({
            DollHouses: APP_CONFIG.dollHouses.reduce((dollHouses, dollHouse) => {
                dollHouses[dollHouse.id] = {
                    LoadState: { state: "idle" },
                    UnzipState: { state: "idle" }
                };
                return dollHouses;
            }, {} as DollHouseStorageObject)
        }));
    }

    private getDollHouseConfig(id: number) {
        const dollHouseConfig = APP_CONFIG.dollHouses.find(e => e.id === id);
        if (!dollHouseConfig) throw ERROR_DOLL_HOUSE_LOADER_DOLL_HOUSE_CONFIG_DOES_NOT_EXIST;
        return dollHouseConfig;
    }

    private loadStateSetProgress(id: number, state: ProgressState) {
        this.parentSetState(p => ({
            DollHouses: Object.keys(p.DollHouses).reduce((dollHouses, dollHouseIdString) => {
                const dollHouseId = parseInt(dollHouseIdString);
                const dollHouse = p.DollHouses[dollHouseId];
                dollHouses[dollHouseId] = {
                    LoadState: id === dollHouseId ? state : { ...dollHouse.LoadState },
                    UnzipState: { ...dollHouse.UnzipState }
                };
                return dollHouses;
            }, {} as DollHouseStorageObject)
        }));
    }

    private unzipStateSetProgress(id: number, state: ProgressState, callback?: Callback) {
        this.parentSetState(
            p => ({
                DollHouses: Object.keys(p.DollHouses).reduce((dollHouses, dollHouseIdString) => {
                    const dollHouseId = parseInt(dollHouseIdString);
                    const dollHouse = p.DollHouses[dollHouseId];
                    dollHouses[dollHouseId] = {
                        LoadState: { ...dollHouse.LoadState },
                        UnzipState: id === dollHouseId ? state : { ...dollHouse.UnzipState }
                    };
                    return dollHouses;
                }, {} as DollHouseStorageObject)
            }),
            () => !!callback && callback()
        );
    }

    private async loadStateSetAllPendingToIdle() {
        await this.parentSetStateAsync(p => ({
            DollHouses: Object.keys(p.DollHouses).reduce((dollHouses, dollHouseIdString) => {
                const dollHouseId = parseInt(dollHouseIdString);
                const dollHouse = p.DollHouses[dollHouseId];
                dollHouses[dollHouseId] = {
                    LoadState: dollHouse.LoadState.state === "pending" ? { state: "idle" } : { ...dollHouse.LoadState },
                    UnzipState: { ...dollHouse.UnzipState }
                };
                return dollHouses;
            }, {} as DollHouseStorageObject)
        }));
    }

    private async loadQueueProcess() {
        if (this.loadInProgress) return;
        if (this.loadQueue.length === 0) return;
        this.loadInProgress = true;

        const queueElement = this.loadQueue[0];

        try {
            const dollHouseConfig = this.getDollHouseConfig(queueElement.id);
            if (!window.DOLL_HOUSES[queueElement.id]) throw ERROR_DOLL_HOUSE_LOADER_DOLL_HOUSE_DATA_DOES_NOT_EXIST;

            this.loadStateSetProgress(queueElement.id, { state: "pending", percent: 0 });

            const response = await axios({
                url: this.qualityMode === 'normal' ? dollHouseConfig.frames.url : dollHouseConfig.framesSmall.url,
                responseType: "arraybuffer",
                cancelToken: new axios.CancelToken(cancel => {
                    this.loadCanceler = cancel;
                }),
                onDownloadProgress: (e: ProgressEvent) => {
                    this.loadStateSetProgress(queueElement.id, {
                        state: "pending",
                        percent: e.total > 0 ? Math.round((e.loaded * 100) / e.total) : 0
                    });
                }
            });
            window.DOLL_HOUSES[queueElement.id].Zip = response.data;

            this.loadStateSetProgress(queueElement.id, { state: "completed" });

            queueElement.resolve.forEach(r => r());
        } catch (e) {
            if (axios.isCancel(e)) {
                return;
            }
            console.log(e);
            this.loadStateSetProgress(queueElement.id, { state: "error" });
            queueElement.reject.forEach(r => r());
        }

        this.loadQueue.splice(0, 1);
        this.loadCanceler = undefined;
        this.loadInProgress = false;

        this.loadQueueProcess();
    }

    public async loadQueueAdd(id: number) {
        if (this.storage.DollHouses[id] === undefined) throw ERROR_DOLL_HOUSE_LOADER_DOLL_HOUSE_DOES_NOT_EXIST;
        if (this.storage.DollHouses[id].LoadState.state === "completed") return;

        const existingQueueElement = this.loadQueue.find(e => e.id === id);

        if (existingQueueElement) {
            return new Promise((resolve, reject) => {
                existingQueueElement.resolve.push(resolve);
                existingQueueElement.reject.push(reject);
            });
        }
        return new Promise((resolve, reject) => {
            this.loadQueue.push({ id: id, resolve: [resolve], reject: [reject] });
            this.loadQueueProcess();
        });
    }

    public async loadQueueAddForce(id: number) {
        if (this.storage.DollHouses[id] === undefined) throw ERROR_DOLL_HOUSE_LOADER_DOLL_HOUSE_DOES_NOT_EXIST;
        if (this.storage.DollHouses[id].LoadState.state === "completed") return;
        const existingQueueElementIndex = this.loadQueue.findIndex(e => e.id === id);

        if (existingQueueElementIndex === 0) {
            return new Promise((resolve, reject) => {
                this.loadQueue[existingQueueElementIndex].resolve.push(resolve);
                this.loadQueue[existingQueueElementIndex].resolve.push(reject);
            });
        }

        if (!!this.loadCanceler) this.loadCanceler();
        this.loadInProgress = false;

        await this.loadStateSetAllPendingToIdle();

        if (existingQueueElementIndex !== -1) {
            const existingQueueElement = this.loadQueue[existingQueueElementIndex];

            this.loadQueue.splice(existingQueueElementIndex, 1);

            return new Promise((resolve, reject) => {
                existingQueueElement.resolve.push(resolve);
                existingQueueElement.reject.push(reject);
                this.loadQueue.splice(0, 0, existingQueueElement);
                this.loadQueueProcess();
            });
        } else {
            return new Promise((resolve, reject) => {
                this.loadQueue.splice(0, 0, { id: id, resolve: [resolve], reject: [reject] });
                this.loadQueueProcess();
            });
        }
    }

    get qualityMode(): "normal" | "mobile" {
        return window.innerWidth < 600 ? "mobile" : "normal";
    }

    private async unzipQueueProcess() {
        if (this.unzipInProgress) return;
        if (this.unzipQueue.length === 0) return;
        this.unzipInProgress = true;

        const queueElement = this.unzipQueue[0];

        try {
            this.unzipStateSetProgress(queueElement.id, { state: "pending", percent: 0 });
            const dollHouseConfig = this.getDollHouseConfig(queueElement.id);
            if (!window.DOLL_HOUSES[queueElement.id]) throw ERROR_DOLL_HOUSE_LOADER_DOLL_HOUSE_DATA_DOES_NOT_EXIST;

            const zip = window.DOLL_HOUSES[queueElement.id].Zip;

            if (!zip) throw ERROR_DOLL_HOUSE_LOADER_DOLL_HOUSE_ZIP_NOT_LOADED;

            const zipData = await JSZip.loadAsync(zip);

            for (let i = 0; i < dollHouseConfig.framesCount; i++) {
                const currentIndex = (dollHouseConfig.frames.startIndex || 0) + i;
                const fileName = dollHouseConfig.frames.fileNamePattern.replace(`#{index}#`, currentIndex.toString());
                const zipFile = zipData.file(fileName);
                if (!!zipFile) {
                    const image = new Image(
                        APP_CONFIG.variables.imageSize.width,
                        APP_CONFIG.variables.imageSize.height
                    );

                    // const decodedFile = await zipFile.async("base64");
                    // image.src = "data:image/jpeg;base64," + decodedFile;

                    const blob = await zipFile.async("blob");
                    image.src = (window.URL || window.webkitURL).createObjectURL(blob);

                    window.DOLL_HOUSES[queueElement.id].Images.push({ Index: i, Image: image });
                }
                this.unzipStateSetProgress(queueElement.id, {
                    state: "pending",
                    percent: Math.round((i * 100) / dollHouseConfig.framesCount)
                });
            }

            this.unzipStateSetProgress(queueElement.id, { state: "completed" });
            queueElement.resolve.forEach(r => r());
        } catch (e) {
            console.log(e);
            this.unzipStateSetProgress(queueElement.id, { state: "error" });
            queueElement.reject.forEach(r => r());
        }

        this.unzipQueue.splice(0, 1);
        this.unzipInProgress = false;
        this.unzipQueueProcess();
    }

    public async unzipQueueAdd(id: number) {
        if (this.storage.DollHouses[id] === undefined) throw ERROR_DOLL_HOUSE_LOADER_DOLL_HOUSE_DOES_NOT_EXIST;
        if (this.storage.DollHouses[id].UnzipState.state === "completed") return;
        if (this.storage.DollHouses[id].LoadState.state !== "completed") await this.loadQueueAdd(id);

        const existingQueueElementIndex = this.unzipQueue.findIndex(e => e.id === id);

        if (existingQueueElementIndex !== -1) {
            return new Promise((resolve, reject) => {
                this.unzipQueue[existingQueueElementIndex].resolve.push(resolve);
                this.unzipQueue[existingQueueElementIndex].reject.push(reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.unzipQueue.push({ id: id, resolve: [resolve], reject: [reject] });
                this.unzipQueueProcess();
            });
        }
    }

    public async unzipQueueAddForce(id: number) {
        if (this.storage.DollHouses[id] === undefined) throw ERROR_DOLL_HOUSE_LOADER_DOLL_HOUSE_DOES_NOT_EXIST;
        if (this.storage.DollHouses[id].UnzipState.state === "completed") return;
        if (this.storage.DollHouses[id].LoadState.state !== "completed") await this.loadQueueAddForce(id);

        const existingQueueElementIndex = this.unzipQueue.findIndex(e => e.id === id);

        if (existingQueueElementIndex === 0) {
            return new Promise((resolve, reject) => {
                this.unzipQueue[existingQueueElementIndex].resolve.push(resolve);
                this.unzipQueue[existingQueueElementIndex].reject.push(reject);
            });
        } else if (existingQueueElementIndex !== -1) {
            const existingQueueElement = this.unzipQueue[existingQueueElementIndex];

            this.unzipQueue.splice(existingQueueElementIndex, 1);

            return new Promise((resolve, reject) => {
                existingQueueElement.resolve.push(resolve);
                existingQueueElement.reject.push(reject);

                this.unzipQueue.splice(1, 0, existingQueueElement);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.unzipQueue.splice(1, 0, { id: id, resolve: [resolve], reject: [reject] });
                this.unzipQueueProcess();
            });
        }
    }

    public async removeDollHouseImages(id: number) {
        if (window.DOLL_HOUSES[id]) {
            window.DOLL_HOUSES[id].Images.length = 0;
        }
        return new Promise(resolve => this.unzipStateSetProgress(id, { state: "idle" }, () => resolve()));
    }
}
