import React from "react";

import { ApartmentSliceProps } from "./Default";

import "./SliceLayout.scss";

const SliceAreaAndCode: React.FC<ApartmentSliceProps> = ({ apartment }) => {
    return (
        <div className="apartment-slice-layout">
            <img src={apartment.layout} alt="" className="apartment-slice-layout__img" />
        </div>
    );
};

export default SliceAreaAndCode;
