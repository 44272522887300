import React, { useState } from "react";

import {
    StaticSliceTypeImage,
    StaticSliceTypeGallery,
    GalleryItemSet,
    GalleryItemType,
    GalleryItemSet1,
} from "../../../types/Static.types";

import "./StaticSliceGallery.scss";

interface Props {
    slice: StaticSliceTypeGallery;
}

const StaticSliceGallery: React.FC<Props> = ({ slice }) => {
    const [currentPhoto, currentPhotoChange] = useState<number | undefined>(undefined);

    const photos = slice.photoSets.reduce((a, ps) => {
        a.push(...galleryItemSetToItemArray(ps));
        return a;
    }, [] as GalleryItemType[]);

    function openPhoto(photoSetIndex: number, photoIndex: number) {
        const photoSetsCount = slice.photoSets.reduce(
            (count, photoSet, index) => (index >= photoSetIndex ? count : count + galleryItemSetToCount(photoSet)),
            0
        );
        const index = photoSetsCount + photoIndex;
        console.log(index);
        currentPhotoChange(index);
    }

    function changePhoto(direction: -1 | 1) {
        currentPhotoChange((oldIndex) => {
            if (photos.length < 1) return 0;
            let newIndex = (oldIndex || 0) + direction;
            return newIndex < 0 ? photos.length - 1 : newIndex >= photos.length ? 0 : newIndex;
        });
    }

    const currentPhotoItem = currentPhoto !== undefined ? photos[currentPhoto] : undefined;

    return (
        <div className={`static-slice-gallery `}>
            {slice.photoSets.map((photoSet, index) => (
                <div className="static-slice-gallery__tile" key={index}>
                    <div className="static-slice-gallery__tile-ratio"></div>
                    <div className={`static-slice-gallery__tile-content type-${photoSet.type}`} key={index}>
                        {galleryItemSetRender(photoSet, (photoIndex) => openPhoto(index, photoIndex))}
                    </div>
                </div>
            ))}
            {currentPhotoItem !== undefined ? (
                <div className="static-slice-gallery__viewer">
                    <div className="static-slice-gallery__viewer-bg"></div>
                    <div className="static-slice-gallery__viewer-display">
                        <div className="static-slice-gallery__viewer-photo">
                            {currentPhotoItem.type === "photo" ? (
                                <img
                                    src={currentPhotoItem.img}
                                    alt=""
                                    className="static-slice-gallery__viewer-photo-img"
                                />
                            ) : currentPhotoItem.type === "youtube" ? (
                                <div className="static-slice-gallery__viewer-player-content">
                                    <iframe
                                        src={currentPhotoItem.youtubeUrl}
                                        className="static-slice-gallery__viewer-youtube-iframe"
                                    />
                                </div>
                            ) : currentPhotoItem.type === "video" ? (
                                <div className="static-slice-gallery__viewer-player-content">
                                    <video className="static-slice-gallery__viewer-video-player" controls>
                                        <source src={currentPhotoItem.video} type="video/mp4"></source>
                                    </video>
                                </div>
                            ) : null}
                        </div>
                        <div className="static-slice-gallery__viewer-arrow left" onClick={() => changePhoto(-1)}>
                            keyboard_arrow_left
                        </div>
                        <div className="static-slice-gallery__viewer-arrow right" onClick={() => changePhoto(1)}>
                            keyboard_arrow_right
                        </div>
                        <div
                            className="static-slice-gallery__viewer-close"
                            onClick={() => currentPhotoChange(undefined)}
                        >
                            close
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default StaticSliceGallery;

const GalleryItem: React.FC<{ item: GalleryItemType; onClick: () => void }> = ({ item, onClick }) => {
    switch (item.type) {
        case "photo":
            return (
                <div className="static-slice-gallery-item" onClick={() => onClick()}>
                    <div className="static-slice-gallery-item__photo">
                        <img src={item.img} alt="" className="static-slice-gallery-item__photo-img" />
                    </div>
                </div>
            );

        case "text":
            return (
                <div className="static-slice-gallery-item">
                    <div className="static-slice-gallery-item__text">
                        {item.title ? <div className="static-slice-gallery-item__text-title">{item.title}</div> : null}
                        {item.description ? (
                            <div className="static-slice-gallery-item__text-description">{item.description}</div>
                        ) : null}
                        {item.img ? (
                            <img src={item.img} alt="" className="static-slice-gallery-item__text-img" />
                        ) : null}
                    </div>
                </div>
            );

        case "video":
            return (
                <div className="static-slice-gallery-item" onClick={() => onClick()}>
                    <div className="static-slice-gallery-item__video">
                        <img src={item.posterImg} alt="" className="static-slice-gallery-item__video-img" />
                        <div className="static-slice-gallery-item__video-play-icon">play_arrow</div>
                    </div>
                </div>
            );

        case "youtube":
            return (
                <div className="static-slice-gallery-item" onClick={() => onClick()}>
                    <div className="static-slice-gallery-item__video">
                        <img src={item.posterImg} alt="" className="static-slice-gallery-item__video-img" />
                        <div className="static-slice-gallery-item__video-play-icon">play_arrow</div>
                    </div>
                </div>
            );

        default:
            return null;
    }
};

function galleryItemSetRender(photoSet: GalleryItemSet, onClick: (photoIndex: number) => void) {
    return photoSet.items.map((item, index) =>
        index < gallerySetToCount(photoSet) ? (
            <GalleryItem key={index} item={item} onClick={() => onClick(index)} />
        ) : null
    );
}

function gallerySetToCount(gallerySet: GalleryItemSet) {
    switch (gallerySet.type) {
        case "1111":
            return 1;

        case "1212":
        case "1122":
            return 2;

        case "1123":
        case "1213":
        case "1323":
        case "1233":
            return 3;

        case "1234":
            return 4;
    }
}

const viewerTypes: GalleryItemType["type"][] = ["photo", "video", "youtube"];

function galleryItemSetToItemArray(galleryItemSet: GalleryItemSet) {
    return galleryItemSet.items.filter((i) => viewerTypes.includes(i.type));
}

function galleryItemSetToCount(galleryItemSet: GalleryItemSet) {
    return galleryItemSet.items.filter(
        (i, index) => index < gallerySetToCount(galleryItemSet) && viewerTypes.includes(i.type)
    ).length;
}
