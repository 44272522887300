import React from "react";

import { ApartmentSliceProps } from "./Default";

import "./SliceRoomList.scss";
import { roomTypeToLabel } from "../../../utils/BasicFunctions";

const SliceRoomList: React.FC<ApartmentSliceProps> = ({ apartment }) => {
    return (
        <div className="apartment-slice-room-list">
            {(apartment.roomList ?? []).map((room, index) => (
                <div className="apartment-slice-room-list__room" key={index}>
                    <div className="apartment-slice-room-list__label">{roomTypeToLabel(room.type)}</div>
                    <div className="apartment-slice-room-list__area">
                        {room.area} m<sup>2</sup>
                    </div>
                </div>
            ))}
            <div className="apartment-slice-room-list__empty"></div>
            <div className="apartment-slice-room-list__empty"></div>
            <div className="apartment-slice-room-list__empty"></div>
        </div>
    );
};

export default SliceRoomList;
