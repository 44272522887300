import React from "react";
import ReactDOM from "react-dom";

import "./scss/index.scss";

import App from "./App";
import { AppConfig } from "./types/Config.types";
import { WindowViewData } from "./context/ViewLoader.types";
import { WindowDollHouseData } from "./context/DollHouseLoader.types";
import { WindowViewFramesData } from "./context/ViewFramesLoader.types";
import { StaticConfig } from "./types/Static.types";
// import JSZip from "jszip";
// import { ZIP, ImageElement } from "./types/Zip.types";
// import { ViewStorage } from "./types/View.types";

declare global {
    const APP_CONFIG: AppConfig;
    const STATIC_CONFIG: StaticConfig;

    interface Window {
        VIEWS: WindowViewData;
        DOLL_HOUSES: WindowDollHouseData;
        VIEW_FRAMES: WindowViewFramesData;
    }
}

ReactDOM.render(<App />, document.getElementById("root"));
