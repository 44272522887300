import React from "react";

import FieldBase from "./FieldBase";
import FieldWrapper from "./FieldWrapper";

import "./RadioBar.scss";

interface RadioBarOption {
    value: number;
    label: string;
    icon?: string;
}

interface RadioBarProps {
    className?: string;
    options: RadioBarOption[];
}

export default class RadioBar extends FieldBase<number, RadioBarProps> {
    private optionRefs = this.props.options.map(() => React.createRef<HTMLDivElement>());

    render() {
        const selectedOptionIndex = this.props.options.findIndex((e) => e.value === this.props.value);

        const selectedOption = this.optionRefs[selectedOptionIndex]?.current;

        const thumbStyle = selectedOption
            ? { width: selectedOption.offsetWidth + 1, left: selectedOption.offsetLeft - 1 }
            : { width: 30, left: 0 };

        return (
            <FieldWrapper {...this.fieldWrapperProps}>
                <div className={`field-radio-bar ${this.props.className ? this.props.className : ""}`}>
                    {this.props.options.map((option, index) => (
                        <div
                            ref={this.optionRefs[index]}
                            className={`field-radio-bar__option ${
                                option.value === this.props.value ? "is-selected" : ""
                            }`}
                            key={option.value}
                            onClick={() => this.onChange(option.value)}
                        >
                            {option.icon ? <i className="field-radio-bar__option-icon">{option.icon}</i> : null}{" "}
                            {option.label}
                        </div>
                    ))}
                    <div className="field-radio-bar__thumb" style={thumbStyle}></div>
                </div>
            </FieldWrapper>
        );
    }
}
