import React from "react";

import FieldBase from "./FieldBase";
import FieldWrapper from "./FieldWrapper";

import "./Check.scss";

export default class Check extends FieldBase<boolean, {}> {
    render() {
        return (
            <div
                className={`field-check ${!this.props.label ? "short" : ""} ${this.props.className || ""}`}
                onClick={() => this.onChange(!this.value)}
            >
                <div className={`field-check__box ${this.props.value ? "is-checked" : ""}`}></div>
                {this.props.label ? <div className="field-check__label">{this.props.label}</div> : null}
            </div>
        );
    }
}
