import React from "react";

import "./FieldWrapper.scss";

export interface FieldWrapperProps {
    label?: string;
    className?: string;
}

export default class FieldWrapper extends React.Component<FieldWrapperProps> {
    render() {
        return (
            <div className={`field-wrapper ${this.props.className || ""}`}>
                {this.props.label ? <div className="field-wrapper__label">{this.props.label}</div> : null}
                <div className="field-wrapper__field">{this.props.children}</div>
            </div>
        );
    }
}
