import React from "react";

import { ApartmentDetailsSlice, Apartment } from "../../../types/Config.types";

import SliceAreaAndCode from "./SliceAreaAndCode";
import SliceAvailability from "./SliceAvailability";
import SliceLayout from "./SliceLayout";
import SliceLocationInfo from "./SliceLocationInfo";
import SliceRoomList from "./SliceRoomList";
import SliceFeatures from "./SliceFeatures";

import "./Slices.scss";

interface Props {
    slices: ApartmentDetailsSlice[];
    apartment: Apartment;
}

const ApartmentDetailsSlices: React.FC<Props> = ({ slices, apartment }) => {
    return (
        <div className="apartment-details-slices">
            {slices.map((slice, index) => {
                switch (slice) {
                    case "AreaAndCode":
                        return <SliceAreaAndCode apartment={apartment} key={index} />;

                    case "Availability":
                        return <SliceAvailability apartment={apartment} key={index} />;

                    case "Layout":
                        return <SliceLayout apartment={apartment} key={index} />;

                    case "LocationInfo":
                        return <SliceLocationInfo apartment={apartment} key={index} />;

                    case "RoomList":
                        return <SliceRoomList apartment={apartment} key={index} />;

                    case "Features":
                        return <SliceFeatures apartment={apartment} key={index} />;

                    default:
                        return null;
                }
            })}
        </div>
    );
};

export default ApartmentDetailsSlices;
