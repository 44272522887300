import React from "react";

import { StaticSlice } from "../../types/Static.types";

import StaticSliceText from "./text/StaticSliceText";
import StaticSliceImageText from "./imageText/StaticSliceImageText";
import StaticSliceImage from "./image/StaticSliceImage";

import "./StaticSlices.scss";
import StaticSliceGallery from "./gallery/StaticSliceGallery";

interface Props {
    slices: StaticSlice[];
}

const StaticSlices: React.FC<Props> = ({ slices }) => {
    return (
        <div className="static-slices">
            {slices.map((slice, index) => {
                switch (slice.type) {
                    case "text":
                        return <StaticSliceText slice={slice} key={index} />;

                    case "imageText":
                        return <StaticSliceImageText slice={slice} key={index} />;

                    case "image":
                        return <StaticSliceImage slice={slice} key={index} />;

                    case "gallery":
                        return <StaticSliceGallery slice={slice} key={index} />;

                    default:
                        return null;
                }
            })}
        </div>
    );
};

export default StaticSlices;
