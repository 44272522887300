import React from "react";

import { ApartmentSliceProps } from "./Default";

import "./SliceAreaAndCode.scss";

const SliceAreaAndCode: React.FC<ApartmentSliceProps> = ({ apartment }) => {
    return (
        <div className="apartment-slice-area-and-code">
            <div className="apartment-slice-area-and-code__group">
                <div className="apartment-slice-area-and-code__label">powierzchnia</div>
                <div className="apartment-slice-area-and-code__value">
                    {apartment.area} m<sup>2</sup>
                </div>
            </div>

            <div className="apartment-slice-area-and-code__group">
                <div className="apartment-slice-area-and-code__label">kod mieszkania</div>
                <div className="apartment-slice-area-and-code__value">{apartment.id}</div>
            </div>
        </div>
    );
};

export default SliceAreaAndCode;
