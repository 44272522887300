import React, { useEffect, useRef, useState, useContext } from "react";
import { Loader } from "@googlemaps/js-api-loader";

import "./Location.scss";
import { cooridnatesToObject } from "../utils/BasicFunctions";
import { LocationMarker } from "../types/Config.types";
import { ContextApp } from "../context";
import { RequestState } from "../types/Generic.types";
import Check from "../form/Check";

import mapConfig from './google-maps.config.json';

interface MarkerObject {
    definition: LocationMarker;
    object: google.maps.Marker;
}

let clickAndSearchVariable = false;
const markers: MarkerObject[] = [];
let googleMap: google.maps.Map | undefined;
let directionsRenderer: google.maps.DirectionsRenderer | undefined;
let directionsService: google.maps.DirectionsService | undefined;

const Location: React.FC = () => {
    const [markerTypes, markerTypesChange] = useState<number[]>(
        APP_CONFIG.locationConfig.markerTypes.map((type) => type.typeId)
    );
    const [search, searchChange] = useState<string>("");
    const [isSearching, isSearchingChange] = useState<RequestState>("idle");
    const [searchResult, searchResultChange] = useState<string>("");
    const [clickAndSearch, setClickAndSearch] = useState<boolean>(false);

    const context = useContext(ContextApp);

    useEffect(() => {
        loadGoogleMap();
    }, []);

    useEffect(() => {
        assignMarkersToMap();
    }, [markerTypes]);

    const map = useRef<HTMLDivElement>(null);

    return (
        <div className="location">
            <div className="location__left">
                <div className="location__map" ref={map}></div>
            </div>
            <div className="location__right">
                <div className="button-menu" onClick={() => context.Common.navOpen()}>
                    menu
                </div>
                <div className="location__header">
                    <div className="location__header-title">Lokalizacja</div>
                </div>
                <div className="location__subtitle">Wyznacz drogę</div>
                <div className="location__search">
                    <div className="location__search-icons">
                        <div className="location__search-icon start">trip_origin</div>
                        <div className="location__search-dot"></div>
                        <div className="location__search-dot"></div>
                        <div className="location__search-dot"></div>
                        <div className="location__search-icon">place</div>
                    </div>
                    <div className="location__search-content">
                        <div className="location__search-start">
                            <div className="location__search-start-name">Zielone Ogrody</div>
                            <div className="location__search-start-address">Mińska 65, 23-324 Warszawa</div>
                        </div>
                        <div className="location__search-end">
                            <div className="location__search-input">
                                <input
                                    type="text"
                                    placeholder="wyszukaj miejsce..."
                                    value={search}
                                    onChange={(e) => searchChange(e.target.value)}
                                    onKeyPress={(e) => e.key === "Enter" && renderDirections()}
                                />
                                <button onClick={() => renderDirections()} className="location__search-input-submit">
                                    search
                                </button>
                            </div>
                            <div className="location__search-divider">
                                <div className="location__search-divider-text">lub</div>
                            </div>
                            <div
                                className="location__search-pointer"
                                onClick={() => {
                                    setClickAndSearch(true);
                                    clickAndSearchVariable = true;
                                }}
                            >
                                <div className="location__search-pointer-icon">
                                    {clickAndSearch ? "gps_fixed" : "gps_not_fixed"}
                                </div>
                                <div className="location__search-pointer-label">Kliknij by wybrać punkt na mapie</div>
                            </div>

                            {isSearching === "pending" ? (
                                <div className="location__directions-field-overlay">Szukanie...</div>
                            ) : null}
                        </div>
                    </div>
                </div>

                {isSearching === "error" ? <div className="location__search-error">Nie znaleziono miejsca</div> : null}

                {isSearching === "completed" ? <div className="location__search-result">{searchResult}</div> : null}

                <div className="location__filters">
                    <div className="location__filters-title">Filtruj pobliskie elementy</div>
                    {APP_CONFIG.locationConfig.markerTypes.map((locationType) => (
                        <div
                            className={`location__filter-item is-active`}
                            key={locationType.typeId}
                            // onClick={() => toggleLocationType(locationType.typeId)}
                        >
                            <div className="location__filter-item-icon">{locationType.icon}</div>
                            <div className="location__filter-item-title">{locationType.title}</div>
                            <Check
                                label=""
                                value={markerTypes.includes(locationType.typeId)}
                                onChange={(e) => toggleLocationType(locationType.typeId)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    async function loadGoogleMap() {
        const loader = new Loader({ apiKey: APP_CONFIG.locationConfig.googleMapsAPIKey });
        await loader.load();
        if (!map.current) return;
        googleMap = new google.maps.Map(map.current, {
            center: cooridnatesToObject(APP_CONFIG.locationConfig.origin),
            zoom: APP_CONFIG.locationConfig.zoom,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            styles: mapConfig as any
        });

        googleMap.addListener("click", (mapMouseEvent) => {
            console.log("click");
            directionFromMapClick(mapMouseEvent.latLng);
        });

        const originMarker = new google.maps.Marker({
            position: cooridnatesToObject(APP_CONFIG.locationConfig.origin),
            title: "Osiedle",
            icon: { url: APP_CONFIG.locationConfig.icon, scaledSize: new google.maps.Size(50, 50) },
        });
        originMarker.setMap(googleMap);

        APP_CONFIG.locationConfig.markers.forEach((marker) => {
            const markerType = APP_CONFIG.locationConfig.markerTypes.find((e) => e.typeId === marker.typeId);
            if (!markerType) return;
            const tempObject = new google.maps.Marker({
                position: cooridnatesToObject(marker.coordinates),
                title: markerType.title,
                icon: { url: markerType.iconMap, scaledSize: new google.maps.Size(50, 50) },
            });
            markers.push({ definition: marker, object: tempObject });
        });

        directionsService = new google.maps.DirectionsService();
        directionsRenderer = new google.maps.DirectionsRenderer({
            suppressMarkers: true,
            polylineOptions: {
                strokeColor: "#0de6a8",
                strokeWeight: 8,
            },
        });
        directionsRenderer.setMap(googleMap);

        assignMarkersToMap();
    }

    function directionFromMapClick(location: google.maps.LatLng) {
        if (!clickAndSearchVariable) return;
        clickAndSearchVariable = false;
        setClickAndSearch(false);
        renderDirections(location);
    }

    function assignMarkersToMap() {
        markers.forEach((marker) => {
            if (markerTypes.includes(marker.definition.typeId)) {
                if (!googleMap) return;
                marker.object.setMap(googleMap);
            } else {
                marker.object.setMap(null);
            }
        });
    }

    function toggleLocationType(id: number) {
        markerTypesChange((previous) => {
            const current = [...previous];
            const index = current.indexOf(id);
            if (index === -1) {
                current.push(id);
            } else {
                current.splice(index, 1);
            }
            return current;
        });
    }

    function renderDirections(destination?: google.maps.LatLng) {
        if (!directionsService) return;
        if (!destination && !search) return;
        isSearchingChange("pending");
        directionsService.route(
            {
                origin: cooridnatesToObject(APP_CONFIG.locationConfig.origin),
                destination: destination || { query: search },
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
                if (status === "OK") {
                    console.log(response);
                    directionsRenderer?.setDirections(response);
                    if (response.routes.length > 0) {
                        searchResultChange(
                            `${response.routes[0].legs[0].distance.text}, ${response.routes[0].legs[0].duration.text}`
                        );
                    }
                    isSearchingChange("completed");
                } else {
                    isSearchingChange("error");
                }
            }
        );
    }
};

export default Location;
