import React, { useRef, useEffect, useState } from "react";

import "./Collapse.scss";

interface Props {
    isCollapsed?: boolean;
    className?: string;
}

const Collapse: React.FC<Props> = ({ children, isCollapsed, className }) => {
    const content = useRef<HTMLDivElement>(null);

    const openedHeight = content.current?.offsetHeight ?? 0;

    const [isLoaded, isLoadedChange] = useState(false);

    let isMounted = true;

    useEffect(() => {
        window.setTimeout(() => {
            if (!isMounted) return;
            isLoadedChange(true);
        }, 20);

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div
            className={`collapse ${isLoaded ? "is-loaded" : ""} ${className || ""}`}
            style={{ height: isCollapsed ? 0 : openedHeight }}
        >
            <div className={`collapse__content`} ref={content}>
                {children}
            </div>
        </div>
    );
};

export default Collapse;
