import { RangeValue } from "../types/Generic.types";
import { RoomType, LocationCoordinates } from "../types/Config.types";

export async function Defer(ms: number) {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), ms);
    });
}

export async function RemoveChildrenFromHTMLElement(element: HTMLElement) {
    const range = new Range();
    range.selectNodeContents(element);
    range.deleteContents();
}

export function AngleFromXY(x0: number, y0: number, x: number, y: number) {
    const angle = (Math.atan2(y - y0, x - x0) * 180) / Math.PI;
    return angle < 0 ? angle + 360 : angle;
}

export function AngleDelta(a0: number, a: number) {
    if (a < a0) {
        return 360 - a0 + a;
    } else {
        return a - a0;
    }
}

export function FrameDistance(current: number, target: number, max: number) {
    // console.log(f0, f1, max);
    if (current === target) return 0;
    const dn = current < target ? target - max - current : target - current;
    const dp = current < target ? target - current : target + max - current;
    return Math.abs(dn) < Math.abs(dp) ? dn : dp;
}

export function FrameInRange(index: number, max: number) {
    if (index < 0) {
        return Math.abs(index) > max ? max : max + index;
    }
    return index < max ? index : index > 2 * max ? max : index - max;
}

export function availabilityToLabel(status: 1 | 2 | 3) {
    switch (status) {
        case 1:
            return "Dostępne";

        case 2:
            return "Rezerwacja";

        case 3:
            return "Sprzedane";
    }
}

export function availabilityToClassName(status: 1 | 2 | 3) {
    switch (status) {
        case 1:
            return "is-available";

        case 2:
            return "is-reserved";

        case 3:
            return "is-sold";
    }
}

const roomTypeLabels: string[] = [
    "",
    "Przedpokój",
    "Łazienka",
    "Toaleta",
    "Kuchnia",
    "Pokój Dzienny",
    "Jadalnia",
    "Sypialnia",
    "Pokój Dziecka",
    "Salon z Aneksem Kuchennym",
    "Taras",
    "Balkon",
    "Loggia",
];

export function roomTypeToLabel(roomType: RoomType) {
    return roomTypeLabels[roomType] || "";
}

export function paramToRangeValue(param: string | null, rangeValue: RangeValue): RangeValue {
    if (!param) return rangeValue;

    const t = param.split("_");

    if (t.length !== 2) return rangeValue;

    const min = parseInt(t[0]);
    const max = parseInt(t[1]);

    if (
        min >= rangeValue.min &&
        min <= rangeValue.max &&
        max >= rangeValue.min &&
        max <= rangeValue.max &&
        min <= max
    ) {
        return {
            min,
            max,
        };
    }

    return rangeValue;
}

export function isMobile() {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || (window as any).opera);
    return check;
}

export function labelByCount(
    count: number,
    defaultLabel: string,
    labelsCount: ([string, number] | [string, number, number] | [string, number, number, true])[]
) {
    for (let i = 0; i < labelsCount.length; i++) {
        const current = labelsCount[i];
        if (current.length === 2 && current[1] === count) {
            return current[0];
        }
        if (current.length === 3 && current[1] <= count && current[2] >= count) {
            return current[0];
        }
        if (current.length === 4 && current[3] && current[1] <= count % 10 && current[2] >= count % 10) {
            return current[0];
        }
    }
    return defaultLabel;
}

export function getViewConfig(id: number) {
    return APP_CONFIG.views.find((e) => e.id === id);
}

export function cooridnatesToObject(coordinates: LocationCoordinates): google.maps.LatLngLiteral {
    return {
        lat: coordinates[0],
        lng: coordinates[1],
    };
}
