import React from "react";

import { BaseComponent } from "../../utils/BaseComponent";

import "./ApartmentNavigator.scss";

export type ApartmentNavigationState = "dollhouse" | "3d" | "3dbalcony" | "viewer" | "photo";

export const ApartmentNavigationStateArray: ApartmentNavigationState[] = [
    "dollhouse",
    "3d",
    "3dbalcony",
    "viewer",
    "photo",
];

interface NavigationElement {
    state: ApartmentNavigationState;
    icon: string;
    label: React.ReactNode;
}

const elements: NavigationElement[] = [
    {
        state: "3dbalcony",
        icon: "balkon",
        label: (
            <>
                WIDOK
                <br />Z BALKONU
            </>
        ),
    },
    {
        state: "3d",
        icon: "3d",
        label: (
            <>
                SPACER
                <br />W MIESZKANIU
            </>
        ),
    },
    {
        state: "dollhouse",
        icon: "dollhouse",
        label: (
            <>
                WIDOK
                <br />
                DOLLHOUSE
            </>
        ),
    },
    {
        state: "photo",
        icon: "elewacja",
        label: (
            <>
                WIDOK
                <br />
                MIESZKANIA
            </>
        ),
    },
    {
        state: "viewer",
        icon: "budynek",
        label: (
            <>
                WIDOK
                <br />
                BUDYNKU
            </>
        ),
    },
];

interface Props {
    visible: boolean;
    currentState: ApartmentNavigationState;
    setState: (state: ApartmentNavigationState) => void;
    goToSearch: () => void;
}

export default class ApartmentNaviagator extends BaseComponent<Props> {
    render() {
        return (
            <div className={`apartment-navigation ${this.props.visible ? "is-visible" : ""}`}>
                {elements.map((element) => (
                    <div
                        key={element.state}
                        className={`apartment-navigation__button ${this.activeClass(element.state)}`}
                        onClick={() => this.props.setState(element.state)}
                    >
                        <div className="apartment-navigation__button-icon">{element.icon}</div>
                        <div className="apartment-navigation__button-label">{element.label}</div>
                    </div>
                ))}
                <div className={`apartment-navigation__button `} onClick={() => this.props.goToSearch()}>
                    <div className="apartment-navigation__button-icon">powrot</div>
                    <div className="apartment-navigation__button-label">
                        lista
                        <br />
                        mieszkań
                    </div>
                </div>
            </div>
        );
    }

    private activeClass(state: ApartmentNavigationState) {
        return state === this.props.currentState ? "is-active" : "";
    }
}
