import axios, { Canceler } from "axios";
import JSZip from "jszip";

import { BaseContext, ContextStorage } from "./Base";
import { ViewScene, ViewQueueElement, WindowViewData, ViewStorageObject } from "./ViewLoader.types";
import { ProgressState, Callback } from "../types/Generic.types";
import { isMobile } from "../utils/BasicFunctions";

export interface ViewLoaderStorage {
    Views: ViewStorageObject;
}

export type ViewLoaderStorageKeyType = "ViewLoader";

export const ViewLoaderStorageKey: ViewLoaderStorageKeyType = "ViewLoader";

export const ViewLoaderStorageDefault: ContextStorage<ViewLoaderStorageKeyType, ViewLoaderStorage> = {
    ViewLoader: {
        Views: {},
    },
};

export const ERROR_VIEW_LOADER_VIEW_DOES_NOT_EXIST = new Error("ViewDoesNotExist");
export const ERROR_VIEW_LOADER_VIEW_CONFIG_DOES_NOT_EXIST = new Error("ViewConfigDoesNotExist");
export const ERROR_VIEW_LOADER_VIEW_DATA_DOES_NOT_EXIST = new Error("ViewDataDoesNotExist");
export const ERROR_VIEW_LOADER_VIEW_ZIP_NOT_LOADED = new Error("ViewZipNotLoaded");

export class ViewLoader extends BaseContext<ViewLoaderStorage, ViewLoaderStorageKeyType> {
    private loadQueue: ViewQueueElement[] = [];
    private loadCanceler: Canceler | undefined;
    private loadInProgress: boolean = false;

    private unzipQueue: ViewQueueElement[] = [];
    private unzipInProgress: boolean = false;

    public async init() {
        window.VIEWS = APP_CONFIG.views.reduce((views, view) => {
            views[view.id] = {
                Day: { Zip: undefined, Images: [] },
                Night: { Zip: undefined, Images: [] },
            };
            return views;
        }, {} as WindowViewData);
        await this.parentSetStateAsync(() => ({
            Views: APP_CONFIG.views.reduce((views, view) => {
                views[view.id] = {
                    Day: { LoadState: { state: "idle" }, UnzipState: { state: "idle" } },
                    Night: { LoadState: { state: "idle" }, UnzipState: { state: "idle" } },
                };
                return views;
            }, {} as ViewStorageObject),
        }));
        if (!isMobile()) {
            APP_CONFIG.views.forEach((view) => {
                this.unzipQueueAdd(view.id, "Day");
                this.unzipQueueAdd(view.id, "Night");
            });
        } else {
            console.log("it is mobile");
        }
    }

    get qualityMode(): "normal" | "mobile" {
        return window.innerWidth < 600 ? "mobile" : "normal";
    }

    private getViewConfig(viewId: number) {
        const viewConfig = APP_CONFIG.views.find((e) => e.id === viewId);
        if (!viewConfig) throw ERROR_VIEW_LOADER_VIEW_CONFIG_DOES_NOT_EXIST;
        return viewConfig;
    }

    private async loadStateSetAllPendingToIdle() {
        await this.parentSetStateAsync((p) => ({
            Views: Object.keys(p.Views).reduce((views, viewIdString) => {
                const viewId = parseInt(viewIdString);
                const view = p.Views[viewId];
                views[viewId] = {
                    Day: {
                        LoadState:
                            view.Day.LoadState.state === "pending" ? { state: "idle" } : { ...view.Day.LoadState },
                        UnzipState: { ...view.Day.UnzipState },
                    },
                    Night: {
                        LoadState:
                            view.Night.LoadState.state === "pending" ? { state: "idle" } : { ...view.Night.LoadState },
                        UnzipState: { ...view.Night.UnzipState },
                    },
                };
                return views;
            }, {} as ViewStorageObject),
        }));
    }

    private loadStateSetProgress(id: number, scene: ViewScene, state: ProgressState) {
        this.parentSetState((p) => ({
            Views: Object.keys(p.Views).reduce((views, viewIdString) => {
                const viewId = parseInt(viewIdString);
                const view = p.Views[viewId];
                views[viewId] = {
                    Day: {
                        LoadState: scene === "Day" && id === viewId ? state : { ...view.Day.LoadState },
                        UnzipState: { ...view.Day.UnzipState },
                    },
                    Night: {
                        LoadState: scene === "Night" && id === viewId ? state : { ...view.Night.LoadState },
                        UnzipState: { ...view.Night.UnzipState },
                    },
                };
                return views;
            }, {} as ViewStorageObject),
        }));
    }

    private unzipStateSetProgress(id: number, scene: ViewScene, state: ProgressState, callback?: Callback) {
        this.parentSetState(
            (p) => ({
                Views: Object.keys(p.Views).reduce((views, viewIdString) => {
                    const viewId = parseInt(viewIdString);
                    const view = p.Views[viewId];
                    views[viewId] = {
                        Day: {
                            LoadState: { ...view.Day.LoadState },
                            UnzipState: scene === "Day" && id === viewId ? state : { ...view.Day.UnzipState },
                        },
                        Night: {
                            LoadState: { ...view.Night.LoadState },
                            UnzipState: scene === "Night" && id === viewId ? state : { ...view.Night.UnzipState },
                        },
                    };
                    return views;
                }, {} as ViewStorageObject),
            }),
            () => !!callback && callback()
        );
    }

    private async loadQueueProcess() {
        if (this.loadInProgress) return;
        if (this.loadQueue.length === 0) return;
        this.loadInProgress = true;

        const queueElement = this.loadQueue[0];

        try {
            const viewConfig = this.getViewConfig(queueElement.viewId);

            if (!window.VIEWS[queueElement.viewId]) throw ERROR_VIEW_LOADER_VIEW_DATA_DOES_NOT_EXIST;

            this.loadStateSetProgress(queueElement.viewId, queueElement.scene, { state: "pending", percent: 0 });

            const response = await axios({
                url:
                    queueElement.scene === "Day"
                        ? this.qualityMode === "normal"
                            ? viewConfig.framesDay.url
                            : viewConfig.framesSmallDay.url
                        : this.qualityMode === "normal"
                        ? viewConfig.framesNight.url
                        : viewConfig.framesSmallNight.url,
                responseType: "arraybuffer",
                cancelToken: new axios.CancelToken((cancel) => {
                    this.loadCanceler = cancel;
                }),
                onDownloadProgress: (e: ProgressEvent) =>
                    this.loadStateSetProgress(queueElement.viewId, queueElement.scene, {
                        state: "pending",
                        percent: e.total > 0 ? Math.round((e.loaded * 100) / e.total) : 0,
                    }),
            });

            window.VIEWS[queueElement.viewId][queueElement.scene].Zip = response.data;

            this.loadStateSetProgress(queueElement.viewId, queueElement.scene, { state: "completed" });

            queueElement.resolve.forEach((r) => r());
        } catch (e) {
            if (axios.isCancel(e)) {
                return;
            }
            console.log(e);
            this.loadStateSetProgress(queueElement.viewId, queueElement.scene, { state: "error" });
            queueElement.reject.forEach((r) => r());
        }

        this.loadQueue.splice(0, 1);

        this.loadCanceler = undefined;
        this.loadInProgress = false;

        this.loadQueueProcess();
    }

    public async loadQueueAdd(id: number, scene: ViewScene) {
        if (this.storage.Views[id] === undefined) throw ERROR_VIEW_LOADER_VIEW_DOES_NOT_EXIST;

        if (this.storage.Views[id][scene].LoadState.state === "completed") return;

        const existingQueueElement = this.loadQueue.find((e) => e.viewId === id && e.scene === scene);

        if (existingQueueElement) {
            return new Promise((resolve, reject) => {
                existingQueueElement.resolve.push(resolve);
                existingQueueElement.reject.push(reject);
            });
        }

        return new Promise((resolve, reject) => {
            this.loadQueue.push({ viewId: id, scene: scene, resolve: [resolve], reject: [reject] });
            this.loadQueueProcess();
        });
    }

    public async loadQueueAddForce(id: number, scene: ViewScene) {
        if (this.storage.Views[id] === undefined) throw ERROR_VIEW_LOADER_VIEW_DOES_NOT_EXIST;

        if (this.storage.Views[id][scene].LoadState.state === "completed") return;

        const existingQueueElementIndex = this.loadQueue.findIndex((e) => e.viewId === id && e.scene === scene);

        if (existingQueueElementIndex === 0) {
            return new Promise((resolve, reject) => {
                this.loadQueue[existingQueueElementIndex].resolve.push(resolve);
                this.loadQueue[existingQueueElementIndex].resolve.push(reject);
            });
        }

        if (!!this.loadCanceler) this.loadCanceler();

        this.loadInProgress = false;

        await this.loadStateSetAllPendingToIdle();

        if (existingQueueElementIndex !== -1) {
            const existingQueueElement = this.loadQueue[existingQueueElementIndex];

            this.loadQueue.splice(existingQueueElementIndex, 1);

            return new Promise((resolve, reject) => {
                existingQueueElement.resolve.push(resolve);
                existingQueueElement.reject.push(reject);
                this.loadQueue.splice(0, 0, existingQueueElement);
                this.loadQueueProcess();
            });
        } else {
            return new Promise((resolve, reject) => {
                this.loadQueue.splice(0, 0, { viewId: id, scene: scene, resolve: [resolve], reject: [reject] });
                this.loadQueueProcess();
            });
        }
    }

    private async unzipQueueProcess() {
        if (this.unzipInProgress) return;
        if (this.unzipQueue.length === 0) return;
        this.unzipInProgress = true;

        const queueElement = this.unzipQueue[0];

        try {
            this.unzipStateSetProgress(queueElement.viewId, queueElement.scene, { state: "pending", percent: 0 });
            const viewConfig = this.getViewConfig(queueElement.viewId);
            if (!window.VIEWS[queueElement.viewId]) throw ERROR_VIEW_LOADER_VIEW_DATA_DOES_NOT_EXIST;

            const zip = window.VIEWS[queueElement.viewId][queueElement.scene].Zip;

            if (!zip) throw ERROR_VIEW_LOADER_VIEW_ZIP_NOT_LOADED;

            const zipData = await JSZip.loadAsync(zip);

            const sceneConfig =
                queueElement.scene === "Day"
                    ? this.qualityMode === "normal"
                        ? viewConfig.framesDay
                        : viewConfig.framesSmallDay
                    : this.qualityMode === "normal"
                    ? viewConfig.framesNight
                    : viewConfig.framesSmallNight;

            console.log(viewConfig.framesCount);
            console.time("t");

            for (let i = 0; i < viewConfig.framesCount; i++) {
                const currentIndex = (sceneConfig.startIndex || 0) + i;
                const fileName = sceneConfig.fileNamePattern.replace(`#{index}#`, currentIndex.toString());
                const zipFile = zipData.file(fileName);
                if (!!zipFile) {
                    const image = new Image(
                        APP_CONFIG.variables.imageSize.width,
                        APP_CONFIG.variables.imageSize.height
                    );

                    const blob = await zipFile.async("blob");
                    image.src = (window.URL || window.webkitURL).createObjectURL(blob);

                    // const decodedFile = await zipFile.async("base64");
                    // image.src = "data:image/jpeg;base64," + decodedFile;
                    window.VIEWS[queueElement.viewId][queueElement.scene].Images.push({ Index: i, Image: image });
                }
                this.unzipStateSetProgress(queueElement.viewId, queueElement.scene, {
                    state: "pending",
                    percent: Math.round((i * 100) / viewConfig.framesCount),
                });
            }

            console.timeEnd("t");

            this.unzipStateSetProgress(queueElement.viewId, queueElement.scene, { state: "completed" });
            queueElement.resolve.forEach((r) => r());
        } catch (e) {
            console.log(e);
            this.unzipStateSetProgress(queueElement.viewId, queueElement.scene, { state: "error" });
            queueElement.reject.forEach((r) => r());
        }

        this.unzipQueue.splice(0, 1);
        this.unzipInProgress = false;
        this.unzipQueueProcess();
    }

    public async unzipQueueAdd(id: number, scene: ViewScene) {
        if (this.storage.Views[id] === undefined) throw ERROR_VIEW_LOADER_VIEW_DOES_NOT_EXIST;
        if (this.storage.Views[id][scene].UnzipState.state === "completed") return;
        if (this.storage.Views[id][scene].LoadState.state !== "completed") await this.loadQueueAdd(id, scene);

        const existingQueueElementIndex = this.unzipQueue.findIndex((e) => e.viewId === id && e.scene === scene);

        if (existingQueueElementIndex !== -1) {
            return new Promise((resolve, reject) => {
                this.unzipQueue[existingQueueElementIndex].resolve.push(resolve);
                this.unzipQueue[existingQueueElementIndex].reject.push(reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.unzipQueue.push({ viewId: id, scene: scene, resolve: [resolve], reject: [reject] });
                this.unzipQueueProcess();
            });
        }
    }

    public async unzipQueueAddForce(id: number, scene: ViewScene) {
        if (this.storage.Views[id] === undefined) throw ERROR_VIEW_LOADER_VIEW_DOES_NOT_EXIST;
        if (this.storage.Views[id][scene].UnzipState.state === "completed") return;
        if (this.storage.Views[id][scene].LoadState.state !== "completed") await this.loadQueueAddForce(id, scene);

        const exisitingQueueElementIndex = this.unzipQueue.findIndex((e) => e.viewId === id && e.scene === scene);

        if (exisitingQueueElementIndex === 0) {
            return new Promise((resolve, reject) => {
                this.unzipQueue[exisitingQueueElementIndex].resolve.push(resolve);
                this.unzipQueue[exisitingQueueElementIndex].reject.push(reject);
            });
        } else if (exisitingQueueElementIndex !== -1) {
            const existingQueueElement = this.unzipQueue[exisitingQueueElementIndex];

            this.unzipQueue.splice(exisitingQueueElementIndex, 1);

            return new Promise((resolve, reject) => {
                existingQueueElement.resolve.push(resolve);
                existingQueueElement.reject.push(reject);

                this.unzipQueue.splice(1, 0, existingQueueElement);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.unzipQueue.splice(1, 0, { viewId: id, scene: scene, resolve: [resolve], reject: [reject] });
                this.unzipQueueProcess();
            });
        }
    }

    public async removeViewImages(id: number, scene: ViewScene) {
        if (window.VIEWS[id]) {
            window.VIEWS[id][scene].Images.length = 0;
        }
        return new Promise((resolve) => this.unzipStateSetProgress(id, scene, { state: "idle" }, () => resolve()));
    }
}
