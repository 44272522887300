import React from "react";

import { StaticSliceTypeImage } from "../../../types/Static.types";

import "./StaticSliceImage.scss";

interface Props {
    slice: StaticSliceTypeImage;
}

const StaticSliceImage: React.FC<Props> = ({ slice }) => {
    return (
        <div className={`static-slice-image `}>
            <div className={`static-slice-image__image-wrapper ${slice.imgClass || ""}`}>
                <div className={`static-slice-image__image-content `}>
                    <img src={slice.img} alt="" className="static-slice-image__image" />
                </div>
            </div>
        </div>
    );
};

export default StaticSliceImage;
