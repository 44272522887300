import React from "react";

import { BaseComponent } from "../utils/BaseComponent";
import Nav from "../components/Nav";
import logo from "../assets/logo.png";

import "./Compare.scss";
import { Apartment, RoomType } from "../types/Config.types";
import { availabilityToLabel, roomTypeToLabel, availabilityToClassName } from "../utils/BasicFunctions";
import { Link } from "react-router-dom";
import { ROUTES } from "./Router";

interface TableElement {
    label: string;
    classNameFirst?: string;
    classNameElement?: string;
    render: (a: Apartment) => string | number | React.ReactNode;
}

interface RoomTypeCount {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
    6: number;
    7: number;
    8: number;
    9: number;
    10: number;
    11: number;
    12: number;
}

const roomTypeKeys: Array<keyof RoomTypeCount> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const roomTypeCount: RoomTypeCount = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
};

export default class Compare extends BaseComponent {
    render() {
        return (
            <div className="compare">
                <div className="compare__header">
                    <div className="compare__header-left">
                        <img src={logo} alt="" className="compare__header-logo" />
                        <div className="compare__header-title">Twoje mieszkania</div>
                    </div>
                    <div className="compare__header-buttons">
                        <Link to={ROUTES.Apartments} className="button low">
                            Powrót
                        </Link>
                        <button className="button low">Wyślij</button>
                        <button className="button low" onClick={() => this.context.Compare.clear()}>
                            Wyczyść
                        </button>
                    </div>
                    <div className="compare__header-right">
                        <div className="button-menu" onClick={() => this.context.Common.navOpen()}>
                            menu
                        </div>
                    </div>
                </div>
                {this.apartments.length === 0 ? (
                    // <div className="compare__empty">
                    //     <div className="compare__empty-text">
                    //         Nie wybrano żadnego mieszkania do porównania.
                    //         <br />
                    //         Wybierz mieszkania do porównania.
                    //     </div>
                    //     <Link to="/Apartments" className="button">
                    //         Wyszukiwarka mieszkań
                    //     </Link>
                    // </div>

                    <div className="compare__add-more">
                        <div className="compare__add-more-title">
                            Nie wybrano żadnego mieszkania do porównania.
                            <br />
                            Wybierz mieszkania do porównania.
                        </div>
                        <Link className="button is-on" to={ROUTES.Apartments}>
                            Wyszukiwarka mieszkań
                        </Link>
                    </div>
                ) : (
                    <>
                        <div className="compare__list">
                            <table className="compare__table">
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        {this.apartments.map((a) => (
                                            <th key={a.id}>{a.name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.tableElements.map((d, index) => (
                                        <tr key={index}>
                                            <td className={`${d.classNameFirst || ""}`}>{d.label}</td>
                                            {this.apartments.map((a) => (
                                                <td key={a.id} className={`${d.classNameElement || ""}`}>
                                                    {d.render(a)}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="compare__add-more">
                            <div className="compare__add-more-title">
                                Przejdź do listy mieszkań, aby dodać więcej lokali do porównania.
                            </div>
                            <Link className="button is-on" to={ROUTES.Apartments}>
                                Przejdź
                            </Link>
                        </div>
                    </>
                )}

                {/* <div className={`compare__footer ${this.apartments.length > 0 ? "is-visible" : ""}`}>
                    <div className="compare__footer-stats">Wybrano do porównania: {this.apartments.length}</div>
                    <div className="compare__footer-button">
                        <button className="button" onClick={() => this.context.Compare.clear()}>
                            Wyczyść
                        </button>
                    </div>
                </div> */}
            </div>
        );
    }

    get apartments() {
        return this.context.ApartmentConfigLoader.storage.Apartments.filter((a) =>
            this.context.Compare.storage.apartmentIds.includes(a.id)
        );
    }

    get roomTypeCalculated() {
        return this.apartments
            .map((a) => {
                const o = { ...roomTypeCount };
                (a.roomList ?? []).forEach((room) => {
                    o[room.type]++;
                });
                return o;
            })
            .reduce(
                (o, a) => {
                    roomTypeKeys.forEach((k) => {
                        if (a[k] > o[k]) {
                            o[k] = a[k];
                        }
                    });
                    return o;
                },
                { ...roomTypeCount } as RoomTypeCount
            );
    }

    get roomList() {
        const rooms: [RoomType, number][] = [];

        roomTypeKeys.forEach((k) => {
            for (let i = 0; i < this.roomTypeCalculated[k]; i++) {
                rooms.push([k, i]);
            }
        });

        return rooms;
    }

    get tableElements(): TableElement[] {
        return [
            { label: "Budynek", render: (a) => a.building },
            { label: "Piętro", render: (a) => a.floor },
            {
                label: "Powierzchnia",
                render: (a) => (
                    <>
                        {a.area} m<sup>2</sup>
                    </>
                ),
            },
            { label: "Liczba pokoi", render: (a) => a.rooms },
            {
                label: "Status",
                render: (a) => (
                    <span className={availabilityToClassName(a.availability)}>
                        {" "}
                        {availabilityToLabel(a.availability)}{" "}
                    </span>
                ),
            },
            {
                label: "",
                classNameElement: "no-border-bottom darker",
                classNameFirst: "darker",
                render: (a) => <img className="compare__layout" src={a.layout} alt="" />,
            },
            { label: "Pomieszczenia", render: () => "" },
            ...this.roomList.map(
                (r) =>
                    ({
                        label: `${roomTypeToLabel(r[0])}${r[1] > 0 ? ` ${r[1] + 1}` : ""}`,
                        classNameFirst: "secondary",
                        render: (a: Apartment) => {
                            const area = getRoomTypeIndex(a, r[0], r[1]);
                            return area ? (
                                <>
                                    {area} m<sup>2</sup>
                                </>
                            ) : (
                                "-"
                            );
                        },
                    } as TableElement)
            ),
            {
                label: "",
                render: (a) => (
                    <div className="compare__td-center">
                        <Link className="button" to={`/Apartments?apartment=${a.id}&apartmentState=dollhouse`}>
                            Przejdź
                        </Link>
                        <button className="button icon" onClick={() => this.context.Compare.remove(a.id)}>
                            delete
                        </button>
                    </div>
                ),
            },
        ];
    }
}

function getRoomTypeIndex(apartment: Apartment, roomType: RoomType, index: number) {
    const rooms = (apartment.roomList ?? []).filter((r) => r.type === roomType).sort((a, b) => b.area - a.area);
    if (rooms.length <= index) return undefined;
    return rooms[index].area;
}
