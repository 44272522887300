import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { BaseComponent } from "../utils/BaseComponent";

import Start from "./Start";
import Apartments from "./Apartments";
import ApartmentCard from "./ApartmentCard";
import Compare from "./Compare";
import Location from "./Location";

import Nav from "../components/Nav";
import Static from "./Static";

export const ROUTES = {
    Apartments: "/Apartments",
    ApartmentCard: "/ApartmentCard/:apartmentId",
    Location: "/Location",
    About: "/About",
    Gallery: "/Gallery",
};

export default class Router extends BaseComponent {
    render() {
        return (
            <>
                <div className={`router ${this.context.Common.navIsOpen ? "no-scroll" : ""}`}>
                    <div
                        className="content"
                        style={{
                            marginTop: this.context.Common.navIsOpen
                                ? `-${this.context.Common.storage.navOpenedScrollTop}px`
                                : 0,
                        }}
                    >
                        <Switch>
                            <Route path="/Start" component={Start} />
                            <Route path="/Compare" component={Compare} />
                            <Route path={ROUTES.Apartments} component={Apartments} />
                            <Route path={ROUTES.ApartmentCard} component={ApartmentCard} />
                            <Route path={ROUTES.Location}>
                                <Location />
                            </Route>
                            <Route path={ROUTES.About}>
                                <Static static={STATIC_CONFIG.about} />
                            </Route>
                            <Route path={ROUTES.Gallery}>
                                <Static static={STATIC_CONFIG.gallery} />
                            </Route>
                            <Redirect path="*" to="/Start" />
                        </Switch>
                    </div>
                </div>
                <Nav showNavClose={true} isClosed={!this.context.Common.navIsOpen} />
            </>
        );
    }
}
